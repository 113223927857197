import { Component } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { RiskService } from "@app2/risk/risk-shared/risk.service";
import { hasAnyRiskSku, hasRiskSysAppsSku } from "@app2/risk/utils";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { RiskModuleType } from "@app2/type-defs/risk/risk-types";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { FeaturesService } from "@app2/shared/services/features.service";

@UntilDestroy()
@Component({
    selector: "navigation-top-bar-risk",
    template: `
        <nav *ngIf="hasNewTabNavEnabled"
             class="top-bar new-tab-nav" role="navigation">
            <section class="top-bar-section nav-section-main">
                <div class="title-container">
                    <h1 class="nav-top-item-main">{{ "RISKS" | localize }}</h1>
                    <navbar-risk-menu></navbar-risk-menu>
                </div>
                <ul class="left">
                    <ng-container *ngIf="hasSku">
                        <li [class.active]="navSectionService.isAssessmentsActive$ | async">
                            <a dsRouterLink="/risk/assessments">{{ "RISK_ASSESSMENTS" | localize }}</a>
                        </li>
                        <li [class.active]="isRiskRegister">
                            <a dsRouterLink="/risk/risks/register">{{ "RISK_REGISTER" | localize }}</a>
                        </li>
                        <li [class.active]="isRiskLibrary">
                            <a dsRouterLink="/risk/risks/library">{{ "RISK_LIBRARY" | localize }}</a>
                        </li>
                        <li [class.active]="isControlsRegister">
                            <a dsRouterLink="/risk/controls/register">{{ "CONTROLS_REGISTER" | localize }}</a>
                        </li>
                        <li [class.active]="isControlsLibrary">
                            <a dsRouterLink="/risk/controls/library">{{ "CONTROLS_LIBRARY" | localize }}</a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="!hasSku">
                        <li [class.active]="isRiskLibrary">
                            <a dsRouterLink="/risk/risks/library">{{ "RISK_LIBRARY" | localize}}</a>
                        </li>
                        <li [class.active]="isControlsLibrary">
                            <a dsRouterLink="/risk/controls/library">{{ "CONTROLS_LIBRARY" | localize }}</a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="hasSystemsAppsSku && (isSystemsAppsSelected$ | async)">
                        <li [class.active]="isInventoryRegister">
                            <a dsRouterLink="/risk/inventory/register">{{ "INVENTORY" | localize }}</a>
                        </li>
                        <li [class.active]="isInventoryLibrary">
                            <a dsRouterLink="/risk/inventory/library">{{ "INVENTORY_LIBRARY" | localize }}</a>
                        </li>
                    </ng-container>
                    <li *ngIf="!hasSystemsAppsSku && (isSystemsAppsSelected$ | async)"
                        [class.active]="isInventoryLibrary">
                        <a dsRouterLink="/risk/inventory/library">{{ "INVENTORY_LIBRARY" | localize }}</a>
                    </li>
                </ul>
            </section>
        </nav>
        <nav *ngIf="!hasNewTabNavEnabled"
             class="top-bar" role="navigation">
            <section class="top-bar-section nav-section-main">
                <ul class="left">
                    <li class="nav-top-item-main align-self-end">
                        <navbar-risk-menu></navbar-risk-menu>
                    </li>
                    <ng-container *ngIf="hasSku">
                        <li class="no-extra-spaces">
                            <pr-dropdown class="old-tab-nav">
                                <div pr-dropdown-trigger
                                     [ngClass]="{'link-active': isRiskRegister || isRiskLibrary}">
                                    {{ "RISKS" | localize }}
                                    <i class="far fa-chevron-down"></i>
                                </div>
                                <ul pr-dropdown-body>
                                    <li [class.active]="isRiskRegister">
                                        <a dsRouterLink="/risk/risks/register">{{ "RISK_REGISTER" | localize }}</a>
                                        <i class="far fa-check"></i>
                                    </li>
                                    <li [class.active]="isRiskLibrary">
                                        <a dsRouterLink="/risk/risks/library">{{ "RISK_LIBRARY" | localize }}</a>
                                        <i class="far fa-check"></i>
                                    </li>
                                </ul>
                            </pr-dropdown>
                        </li>
                        <li class="no-extra-spaces">
                            <pr-dropdown class="old-tab-nav">
                                <div pr-dropdown-trigger
                                     [ngClass]="{'link-active': isControlsRegister || isControlsLibrary}">
                                    {{ "CONTROLS" | localize }}
                                    <i class="far fa-chevron-down"></i>
                                </div>
                                <ul pr-dropdown-body>
                                    <li [class.active]="isControlsRegister">
                                        <a dsRouterLink="/risk/controls/register">{{ "CONTROLS_REGISTER" | localize }}</a>
                                        <i class="far fa-check"></i>
                                    </li>
                                    <li [class.active]="isControlsLibrary">
                                        <a dsRouterLink="/risk/controls/library">{{ "CONTROLS_LIBRARY" | localize }}</a>
                                        <i class="far fa-check"></i>
                                    </li>
                                </ul>
                            </pr-dropdown>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="!hasSku">
                        <li>
                            <a class="no-dropdown-item" [class.link-active]="isRiskLibrary"
                               dsRouterLink="/risk/risks/library">
                                {{ "RISK_LIBRARY" | localize}}
                            </a>
                        </li>
                        <li>
                            <a class="no-dropdown-item" [class.link-active]="isControlsLibrary"
                               dsRouterLink="/risk/controls/library">
                                {{ "CONTROLS_LIBRARY" | localize }}
                            </a>
                        </li>
                    </ng-container>

                    <li class="extra-margin-right">
                        <a class="no-dropdown-item" [class.link-active]="navSectionService.isAssessmentsActive$ | async"
                           dsRouterLink="/risk/assessments">
                            {{ "RISK_ASSESSMENTS" | localize }}
                        </a>
                    </li>

                    <pr-dropdown *ngIf="hasSystemsAppsSku && (isSystemsAppsSelected$ | async)"
                                 class="old-tab-nav">
                        <div pr-dropdown-trigger
                             [class.link-active]="isInventoryRegister || isInventoryLibrary">
                            {{ "INVENTORY" | localize }}
                            <i class="far fa-chevron-down"></i>
                        </div>
                        <ul pr-dropdown-body>
                            <li [class.active]="isInventoryRegister">
                                <a dsRouterLink="/risk/inventory/register">{{ "INVENTORY" | localize }}</a>
                                <i class="far fa-check"></i>
                            </li>
                            <li [class.active]="isInventoryLibrary">
                                <a dsRouterLink="/risk/inventory/library">{{ "INVENTORY_LIBRARY" | localize }}</a>
                                <i class="far fa-check"></i>
                            </li>
                        </ul>
                    </pr-dropdown>
                    <li *ngIf="!hasSystemsAppsSku && (isSystemsAppsSelected$ | async)">
                        <a class="no-dropdown-item" [class.link-active]="isInventoryLibrary"
                           dsRouterLink="/risk/inventory/library">
                            {{ "INVENTORY_LIBRARY" | localize }}
                        </a>
                    </li>
                </ul>
            </section>
        </nav>
    `,
    styles: [`
      @import "/src/styles/colors";

      .title-container {
        display: flex;
        align-items: end;
      }

      .align-self-end {
        align-self: end;
      }

      pr-dropdown {
        margin-top: 20px;
        margin-right: 24px;
        font-size: 1rem;
        line-height: 1.25rem;

        *[pr-dropdown-trigger] {
          padding-bottom: 6px;

          i {
            margin-left: 4px;
          }
        }

        ul {
          display: block;
          margin-top: -8px;

          li {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            padding: 0 16px;
            width: 100%;

            i {
              margin-left: 24px;
              padding-right: 0;
            }

            a {
              padding: 0;
            }
          }
        }
      }

      .top-bar-section li.no-extra-spaces {
        margin-right: 0;
      }

      .top-bar-section li.extra-margin-right {
        margin-right: 24px;
      }

      .top-bar-section a.no-dropdown-item {
        margin-top: 20px;
        height: 36px;
        font-size: 1rem;

        &.link-active:hover {
          color: $ds-blue-6;
          border-bottom: none;
        }
      }

      .link-active {
        color: $ds-blue-6;
        border-bottom: 4px solid $ds-blue-6;
      }
    `],
})
export class NavigationTopBarRiskComponent {
    hasSku: boolean;
    hasSystemsAppsSku: boolean;
    isRiskRegister: boolean;
    isRiskLibrary: boolean;
    isControlsRegister: boolean;
    isControlsLibrary: boolean;
    isInventoryRegister: boolean;
    isInventoryLibrary: boolean;
    hasNewTabNavEnabled: boolean;

    constructor(public navSectionService: ActiveNavSectionService,
                public riskService: RiskService,
                private featuresService: FeaturesService,
                orgsService: OrgsService) {
        const orgSkus = orgsService.getCurrentOrg().productSkus;
        this.hasSku = hasAnyRiskSku(orgSkus);
        this.hasSystemsAppsSku = hasRiskSysAppsSku(orgSkus);
        navSectionService.isRiskRegisterActive$
            .pipe(untilDestroyed(this))
            .subscribe((isActive) => this.isRiskRegister = isActive);
        navSectionService.isRiskLibraryActive$
            .pipe(untilDestroyed(this))
            .subscribe((isActive) => this.isRiskLibrary = isActive);
        navSectionService.isControlsRegisterActive$
            .pipe(untilDestroyed(this))
            .subscribe((isActive) => this.isControlsRegister = isActive);
        navSectionService.isControlsLibraryActive$
            .pipe(untilDestroyed(this))
            .subscribe((isActive) => this.isControlsLibrary = isActive);
        navSectionService.isInventoryRegisterActive$
            .pipe(untilDestroyed(this))
            .subscribe(isActive => this.isInventoryRegister = isActive);
        navSectionService.isInventoryLibraryActive$
            .pipe(untilDestroyed(this))
            .subscribe(isActive => this.isInventoryLibrary = isActive);

        this.featuresService.getFeature$("newTabNav")
            .pipe(untilDestroyed(this))
            .subscribe(isEnabled => {
                this.hasNewTabNavEnabled = isEnabled;
                document.documentElement.style.setProperty("--nav-top-bar-height",
                    isEnabled ? "5.75rem" : "3.5rem");
            });
    }

    get isSystemsAppsSelected$(): Observable<boolean> {
        return this.riskService.isModuleSelected$(RiskModuleType.SYSTEMS_AND_APPLICATIONS);
    }
}
