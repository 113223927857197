import { HttpClient } from "@angular/common/http";
import { OrgsService } from "@app2/account/orgs.service";
import { camelToSnake, snakeToCamel } from "@app2/util/case-converter";
import { Dictionary } from "@app2/util/common-types";
import { Observable, throwError } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import * as _ from "underscore";

export function unwrapIfAppropriate(response: any): any {
    return (response.results !== undefined) ? response.results : response;
}

export class RestClient {
    private currentOrgId?: uuid;
    constructor(private serverUrl: string,
                private httpClient: HttpClient,
                private orgsService?: OrgsService) {
        if (this.orgsService) {
            this.orgsService.getCurrentOrg$()
                .pipe(filter(org => !!org))
                .subscribe(currentOrg => this.currentOrgId = currentOrg.id);
        }
    }

    public doDelete<T>(url: string, params?: Dictionary<any>, exclude?: string[]): Promise<T> {
        return this.doRequest$<T>("DELETE", url, undefined, params, exclude).toPromise();
    }

    public doGet<T>(url: string, params?: Dictionary<any>, exclude?: string[],
                    headers?: Record<string, string>): Promise<T> {
        return this.doRequest$<T>("GET", url, undefined, params, exclude, headers).toPromise();
    }

    public doPost<T>(url: string, body?: any, params?: Dictionary<any>, exclude?: string[],
                     headers?: Record<string, string>): Promise<T> {
        return this.doRequest$<T>("POST", url, body, params, exclude, headers).toPromise();
    }

    public doPut<T>(url: string, body: any = "", params?: Dictionary<any>, exclude?: string[]): Promise<T> {
        return this.doRequest$<T>("PUT", url, body, params, exclude).toPromise();
    }

    public doDelete$<T>(url: string, params?: Dictionary<any>, exclude?: string[]): Observable<T> {
        return this.doRequest$<T>("DELETE", url, undefined, params, exclude);
    }

    public doGet$<T>(url: string, params?: Dictionary<any>, exclude?: string[]): Observable<T> {
        return this.doRequest$<T>("GET", url, undefined, params, exclude);
    }

    public doPost$<T>(url: string, body?: any, params?: Dictionary<any>, exclude?: string[],
                      headers?: Record<string, string>): Observable<T> {
        return this.doRequest$<T>("POST", url, body, params, exclude, headers);
    }

    public doPut$<T>(url: string, body: any = "", params?: Dictionary<any>, exclude?: string[]): Observable<T> {
        return this.doRequest$<T>("PUT", url, body, params, exclude);
    }

    public downloadCsvPost(url: string, body?: any, params?: Dictionary<any>): Promise<Blob> {
        return this.doRequestBinary("POST", url, body, params, "text/csv", "text");
    }

    public downloadCsvGet(url: string, body?: any, params?: Dictionary<any>): Promise<Blob> {
        return this.doRequestBinary("GET", url, body, params, "text/csv", "text");
    }

    public downloadBinaryGet(url: string, params?: Dictionary<any>): Promise<Blob> {
        return this.doRequestBinary("GET", url, undefined, params, "application/octet-stream", "blob");
    }

    private doRequest$<T>(method: string, url: string, body: any, params?: Dictionary<any>, exclude?: string[],
                          headers?: Record<string, string>): Observable<T> {
        return this.httpClient.request<T>(method,
            this.getFullUrlFor(url),
            {
                body: RestClient.processBody(body, exclude),
                params: RestClient.processParams(params),
                withCredentials: true,
                headers,
            })
            .pipe(
                map(response => snakeToCamel(response, exclude)),
                catchError(errorResponse => throwError(RestClient.processResponseError(errorResponse))),
            );
    }

    public doRequestBinary(method: string, url: string, body: any, params: Dictionary<any>,
                           contentType: string, responseType: "text" | "blob"): Promise<Blob> {
        return this.httpClient.request(method,
            this.getFullUrlFor(url),
            {
                body: RestClient.processBody(body),
                headers: { Accept: contentType },
                params: RestClient.processParams(params),
                withCredentials: true,
                responseType,
            })
            .toPromise()
            .then((resp) => new Blob([resp], { type: contentType }))
            .catch(errorResponse => Promise.reject(RestClient.processResponseError(errorResponse)));
    }

    public doRequestText(method: string, url: string, body: any, params?: Dictionary<any>): Promise<string> {
        return this.httpClient.request(method,
            this.getFullUrlFor(url),
            {
                body: RestClient.processBody(body),
                params: RestClient.processParams(params),
                withCredentials: true,
                headers: { Accept: "text/plain" },
                responseType: "text",
            })
            .toPromise()
            .catch(errorResponse => Promise.reject(RestClient.processResponseError(errorResponse)));
    }

    public getFullUrlFor(url: string): string {
        return this.serverUrl + this.getOrgUrl() + url;
    }

    private getOrgUrl(): string {
        if (!this.orgsService) {
            return "";
        }
        if (!this.currentOrgId) {
            throw new Error("Cannot create HTTP org endpoint with no org.");
        }
        return `/org/${this.currentOrgId}`;
    }

    private static processBody(body: any, exclude?: string[]): any {
        let processedBody = body;
        // If the body is an object, remove keys with undefined values
        if (body && typeof body === "object" && !Array.isArray(body)) {
            processedBody = _.omit(body, value => value === undefined);
        }
        return camelToSnake(processedBody, exclude);
    }

    private static processParams(params?: Dictionary<any>): any {
        if (!params) {
            return params;
        }
        // Remove keys with undefined values
        return _.omit(params, value => value === undefined);
    }

    private static processResponseError(errorResponse: any): any {
        if (errorResponse?.headers?.get("Content-Type")?.includes("application/json")) {
            errorResponse.error = snakeToCamel(errorResponse.error);
        }
        return errorResponse;
    }
}
