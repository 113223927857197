import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { OrgsService } from "@app2/account/orgs.service";
import { WindowService } from "@app2/shared/services/window.service";
import { OrgSummary } from "@app2/type-defs/user/user-types";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, Observable } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";
import * as _ from "underscore";

@UntilDestroy()
@Component({
    selector: "old-navbar-org-selector",
    template: `
        <button test-id="prev-org-button"
                class="link left-org-arrow"
                (click)="selectPreviousOrg(currentOrg?.id)">
            <a>
                <i class="fal fa-angle-left"></i>
            </a>
        </button>
        <pr-dropdown test-id="dropdown">
            <div pr-dropdown-trigger (click)="focusText()" class="title-dropdown-trigger">
                <span class="org-name"> {{ currentOrg?.name }} </span>
            </div>
            <ul pr-dropdown-body class="overflow-auto">
                <!-- $event.stopPropagation() so we can click the search pane without closing the dropdown.-->
                <li class="no-hover" (click)="$event.stopPropagation()">
                    <input #searchTermField
                           type="text"
                           [formControl]="searchTerm"
                           [placeholder]="'BASE_SEARCH' | localize"/>
                    <button class="search-button" type="submit">
                        <i class="far fa-search search-button"></i>
                    </button>
                </li>
                <li *ngFor="let org of filteredOrgs"
                    [ngClass]="{'active': currentOrg?.id === org.id }"
                    class="pr-dropdown-flex-row">
                    <a (click)="selectOrg(org.id)"
                       class="fill-available-space">
                        <i class="far fa-check"></i>
                        <i [class.fa-house]="org.id === homeOrg.id" class="far fa-fw"></i>
                        {{ org.name }}
                    </a>
                    <a [href]="getOrgHref(org.id)"
                       target="_blank" rel="noopener noreferrer">
                        <i class="far fa-external-link visible-icon padded"></i>
                    </a>
                </li>
            </ul>
        </pr-dropdown>
        <button test-id="next-org-button"
                class="link right-org-arrow"
                (click)="selectNextOrg(currentOrg?.id)">
            <a>
                <i class="fal fa-angle-right"></i>
            </a>
        </button>
    `,
    styles: [`
        @import "/src/styles/colors";

        .fa-fw {
            margin-left: 4px;
        }

        .left-org-arrow {
            height: 15px;
            top: 0px;
            left: -3px;
            padding: 0;
        }

        .right-org-arrow {
            height: 15px;
            top: 0px;
            left: 3px;
            padding: 0;
        }

        .fa-house {
            color: $gray-7 !important;
        }
    `],
})
export class OldNavbarOrgSelectorComponent {
    currentOrg: OrgSummary;
    filteredOrgs: OrgSummary[];
    homeOrg: OrgSummary;

    @ViewChild("searchTermField")
    private searchTermField: ElementRef;
    searchTerm = new FormControl("");

    constructor(private readonly orgsService: OrgsService,
                private router: Router,
                private windowService: WindowService) {
        const allOrgs$: Observable<OrgSummary[]> = orgsService.getOrgsInfo$()
            .pipe(
                // Ignore events until we are correctly setup
                filter(({ homeOrg }) => !!homeOrg),
                map(({ homeOrg, currentOrg, authorizedOrgs }) => {
                    this.currentOrg = currentOrg;
                    this.homeOrg = homeOrg;
                    return _.sortBy([homeOrg, ...authorizedOrgs], org => org.name.toLowerCase());
                }),
            );

        const searchTerm$: Observable<string> = this.searchTerm.valueChanges.pipe(startWith(""));

        combineLatest([allOrgs$, searchTerm$])
            .pipe(map(([orgs, searchTerm]) =>
                orgs.filter(org => org.name.toLowerCase().includes(searchTerm.toLowerCase()))))
            .pipe(untilDestroyed(this))
            .subscribe(orgs => this.filteredOrgs = orgs);
    }

    focusText() {
        setTimeout(() => this.searchTermField.nativeElement.focus(), 0);
    }

    // Change to the new org, causing a full refresh of the application
    selectOrg(orgId: uuid) {
        this.windowService.navigateTo(this.getOrgHref(orgId));
    }

    getOrgHref(orgId: uuid): string {
        const queryParams = { orgId: orgId };
        const tree = this.router.createUrlTree([],
            { queryParams, queryParamsHandling: "merge" });
        return this.router.serializeUrl(tree);
    }

    selectNextOrg(orgId: uuid) {
        let currIndex = this.filteredOrgs.findIndex(org => org.id === orgId);
        //Modulus for wrap-around
        let nextIndex = (currIndex + 1) % this.filteredOrgs.length;
        return this.selectOrg(this.filteredOrgs[nextIndex].id);
    }

    selectPreviousOrg(orgId: uuid) {
        let currIndex = this.filteredOrgs.findIndex(org => org.id === orgId);
        //Ternary operator for wrap-around
        let prevIndex = currIndex === 0 ? (this.filteredOrgs.length - 1) : currIndex - 1;
        return this.selectOrg(this.filteredOrgs[prevIndex].id);
    }
}
