import { Component, ViewChild } from "@angular/core";
import { AuthStateService } from "@app2/account/auth-state.service";
import { UserService } from "@app2/account/user.service";
import { Person } from "@app2/type-defs/user/user-types";
import { PrDropdownComponent } from "@app2/shared/components/pr-dropdown.component";
import { Observable } from "rxjs";
import { FeaturesService } from "@app2/shared/services/features.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "navbar-user-menu",
    template: `
        <pr-dropdown align="right"
                     [closeOnClick]="false"
                     [showOnHover]="true"
                     test-id="navbar-user-menu-dropdown">
            <div pr-dropdown-trigger>
                <a *ngIf="newNavigationView"
                   class="user-icon-container"
                   dsRouterLink="/settings/profile"
                   test-id="current-user-name">
                    <i class="fas fa-circle-user"></i>
                </a>
                <a *ngIf="!newNavigationView"
                   dsRouterLink="/settings/profile"
                   test-id="current-user-name">
                    <svg-icon src="assets/icons/user_profile.svg"></svg-icon>
                    <span class="dropdown-value current-user-name">
                        {{ (currentUser$ | async)?.fullName }}
                        <i class="ion ion-ios-arrow-down"></i>
                    </span>
                </a>
            </div>
            <ul pr-dropdown-body>
                <li *ngIf="newNavigationView" class="user-item">
                    {{ (currentUser$ | async)?.fullName }}
                </li>
                <li>
                    <inactivity-timeout-toggle></inactivity-timeout-toggle>
                </li>
                <li>
                    <shortcut-keys-toggle></shortcut-keys-toggle>
                </li>
                <li *featureGate="'readonlyToggle'">
                    <readonly-toggle></readonly-toggle>
                </li>
                <li *dsPermissionsRequired="'TRAC_USER'">
                    <dark-mode-toggle></dark-mode-toggle>
                </li>
                <li (click)="logOut()">
                    {{ "LOG_OUT" | localize }}
                </li>
            </ul>
        </pr-dropdown>
    `,
    styles: [
    `
        @import "/src/styles/colors";
        @import "/src/styles/settings";

        .user-icon-container {
            font-size: 1.5rem;

            .fa-circle-user {
                color: $lara-purple;
            }
        }

        .user-item {
            font-weight: $font-weight-demibold;
            text-transform: uppercase;
            pointer-events: none;
            margin: 4px 0;
        }
    `],
})
export class NavbarUserMenuComponent {
    currentUser$: Observable<Person>;
    // This is used from a test
    @ViewChild(PrDropdownComponent) dropdown: PrDropdownComponent;
    newNavigationView: boolean;

    constructor(private readonly authStateService: AuthStateService,
                private readonly featuresService: FeaturesService,
                userService: UserService) {
        this.currentUser$ = userService.getCurrentUser$();
        featuresService.getFeature$("newNavigationView")
            .pipe(untilDestroyed(this))
            .subscribe(isEnabled => this.newNavigationView = isEnabled);
    }

    logOut() {
        this.authStateService.logOut();
    }

}
