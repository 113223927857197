import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { OrgSummary } from "@app2/type-defs/user/user-types";
import { UserClientService } from "@app2/clients/user-client.service";
import { OrgsService } from "@app2/account/orgs.service";

@Component({
    selector: "old-nav-header-bar",
    template: `
        <nav role="navigation" class="top-bar">
            <div class="title-area" test-id="title-org-name">
                <span *ngIf="(authorizedOrgs$ | async).length === 0" class="org-name">{{ (currentOrg$ | async).name }}</span>
                <span *ngIf="(authorizedOrgs$ | async).length > 0">
                <old-navbar-org-selector></old-navbar-org-selector>
            </span>
                <app-version></app-version>
            </div>
            <section>
                <ul class="right">
                    <li>
                        <navbar-user-menu></navbar-user-menu>
                    </li>
                </ul>
            </section>
            <section class="top-bar-section">
                <ul class="right">
                    <li id="help">
                    <span>
                        <a href="https://kb.defensestorm.com/help" target="_blank" rel="noopener noreferrer">
                            <i class="ion-ios-help"></i>
                            {{ 'HELP' | localize }}
                        </a>
                    </span>
                    </li>
                    <li id="connect">
                    <span>
                        <a [href]="freshdeskRedirectUrl" target="_blank" rel="noopener noreferrer">
                            <svg-icon src="assets/icons/connect.svg"></svg-icon>
                            {{ 'MODULE_CONNECT' | localize }}
                        </a>
                    </span>
                    </li>
                </ul>
            </section>
        </nav>
    `,
})
export class OldNavHeaderBarComponent {
    currentOrg$: Observable<OrgSummary>;
    authorizedOrgs$: Observable<OrgSummary[]>;
    freshdeskRedirectUrl: string;

    constructor(userClientService: UserClientService,
                orgsService: OrgsService) {
        this.authorizedOrgs$ = orgsService.getAuthorizedOrgs$();
        this.currentOrg$ = orgsService.getCurrentOrg$();
        this.freshdeskRedirectUrl = userClientService.getFreshdeskRedirectUrl();
        document.documentElement.style.setProperty("--nav-left-bar-width", "6.25rem");
    }
}
