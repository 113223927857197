import { Component } from "@angular/core";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { FeaturesService } from "@app2/shared/services/features.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "navigation-top-bar-reports",
    template: `
        <nav *ngIf="hasNewTabNavEnabled"
             class="top-bar new-tab-nav" role="navigation">
            <section class="top-bar-section nav-section-main">
                <h1 class="nav-top-item-main">{{ "REPORTS" | localize }}</h1>
                <ul class="left">
                    <li [class.active]="navSectionService.isReportsListActive$ | async"
                        *dsPermissionsRequired="'REPORT_VIEW'">
                        <a dsRouterLink="/reports">{{ "REPORT_DOWNLOADS" | localize }}</a></li>
                    <li [class.active]="navSectionService.isReportTemplatesListActive$ | async"
                        *dsPermissionsRequired="'REPORT_VIEW'">
                        <a dsRouterLink="/reports/templates/list">{{ "TEMPLATES" | localize }}</a></li>
                    <li [class.active]="navSectionService.isChartsListActive$ | async"
                        *dsPermissionsRequired="'CHART_VIEW'">
                        <a dsRouterLink="/reports/charts/list">{{ "CHARTS" | localize }}</a></li>
                </ul>
            </section>
        </nav>
        <nav *ngIf="!hasNewTabNavEnabled"
             class="top-bar" role="navigation">
            <section class="top-bar-section nav-section-main">
                <ul class="left extra-margin-top">
                    <li class="nav-top-item-main"
                        [class.active]="navSectionService.isReportsListActive$ | async"
                        *dsPermissionsRequired="'REPORT_VIEW'">
                        <a dsRouterLink="/reports">{{"REPORTS" | localize}}</a></li>
                    <li [class.active]="navSectionService.isReportTemplatesListActive$ | async"
                        *dsPermissionsRequired="'REPORT_VIEW'">
                        <a dsRouterLink="/reports/templates/list">{{"TEMPLATES" | localize}}</a></li>
                    <li [class.active]="navSectionService.isChartsListActive$ | async"
                        *dsPermissionsRequired="'CHART_VIEW'">
                        <a dsRouterLink="/reports/charts/list">{{"CHARTS" | localize}}</a></li>
                </ul>
            </section>
        </nav>
    `,
})
export class NavigationTopBarReportsComponent {
    hasNewTabNavEnabled: boolean;

    constructor(public navSectionService: ActiveNavSectionService,
                private featuresService: FeaturesService) {
        this.featuresService.getFeature$("newTabNav")
            .pipe(untilDestroyed(this))
            .subscribe(isEnabled => {
                this.hasNewTabNavEnabled = isEnabled;
                document.documentElement.style.setProperty("--nav-top-bar-height",
                    isEnabled ? "5.75rem" : "3.5rem");
            });
    }
}
