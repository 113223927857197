import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { UserService } from "@app2/account/user.service";
import { hasAnyFraudSku } from "@app2/fraud/utils";
import { hasAnyRiskSku } from "@app2/risk/utils";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { animate, animateChild, group, keyframes, query, state, style, transition, trigger } from "@angular/animations";
import { Params } from "@angular/router";
import { FeaturesService } from "@app2/shared/services/features.service";
import { combineLatest } from "rxjs";

type NavbarSection = "HOME" | "DASHBOARDS" | "REPORTS" | "TICKETS" | "EVENTS" | "ASSETS" |
    "FRAUD" | "THREAT_SURVEILLANCE" | "GOVERNANCE" | "RISK" | "SETTINGS";
const allSections: NavbarSection[] = ["HOME", "DASHBOARDS", "REPORTS", "TICKETS", "EVENTS", "ASSETS",
    "FRAUD", "THREAT_SURVEILLANCE", "GOVERNANCE", "RISK", "SETTINGS"];

@UntilDestroy()
@Component({
    selector: "navigation-left-bar",
    template: `
        <nav class="left-bar">
            <div [@toggleMenu]="leftBarVisible" class="left-bar-container">
                <div class="header-container">
                    <navbar-org-selector [leftBarVisible]="leftBarVisible"/>
                    <div class="navbar-toggle-button"
                         (click)="toggleLeftBar.emit()"
                         test-id="toggle-left-bar-button">
                        <i class="fas"
                           [ngClass]="leftBarVisible ? 'fa-circle-chevron-left' : 'fa-circle-chevron-right'"></i>
                    </div>
                </div>

                <div class="navbar-section-container"
                     [@toggleContent]="leftBarVisible"
                     [ngClass]="leftBarVisible ? 'left-bar-expanded' : 'left-bar-collapsed'">
                    <ul class="navbar-section">
                        <li [class.active]="navSectionService.isHomeActive$ | async"
                            *featureGate="'cyberRiskReadinessDashboard'">
                            <a class="navbar-item"
                               [matTooltip]="'HOME' | localize"
                               [matTooltipDisabled]="leftBarVisible"
                               matTooltipPosition="right"
                               [dsRouterLink]="sectionLinks['HOME']">
                                <i class="fal fa-fw fa-home-lg"></i>
                                <span *ngIf="leftBarVisible">{{ 'HOME' | localize }}</span>
                            </a>
                        </li>
                        <li [class.active]="navSectionService.isDashboardsActive$ | async"
                            *dsPermissionsRequired="'DASHBOARD_VIEW'">
                            <a class="navbar-item"
                               [matTooltip]="'DASHBOARDS' | localize"
                               [matTooltipDisabled]="leftBarVisible"
                               matTooltipPosition="right"
                               [dsRouterLink]="sectionLinks['DASHBOARDS']">
                                <i class="fal fa-fw fa-chart-line"></i>
                                <span *ngIf="leftBarVisible">{{ 'DASHBOARDS' | localize }}</span>
                            </a>
                        </li>
                        <li [class.active]="navSectionService.isReportsActive$ | async"
                            *dsPermissionsRequired="'any: REPORT_VIEW, CHART_VIEW'"
                            test-id="main-reports-container">
                            <a class="navbar-item"
                               [matTooltip]="'REPORTS' | localize"
                               [matTooltipDisabled]="leftBarVisible"
                               matTooltipPosition="right"
                               [dsRouterLink]="sectionLinks['REPORTS']">
                                <i class="fal fa-fw fa-file-chart-column"></i>
                                <span *ngIf="leftBarVisible">{{ 'REPORTS' | localize }}</span>
                            </a>
                        </li>
                        <li [class.active]="navSectionService.isTicketsActive$ | async"
                            *dsPermissionsRequired="'INCIDENT_VIEW'"
                            test-id="main-tickets-container">
                            <a class="navbar-item"
                               [matTooltip]="'TICKETS' | localize"
                               [matTooltipDisabled]="leftBarVisible"
                               matTooltipPosition="right"
                               [dsRouterLink]="sectionLinks['TICKETS']"
                               test-id="module-tickets-description">
                                <i class="fal fa-fw fa-ballot-check"></i>
                                <span *ngIf="leftBarVisible">{{ 'TICKETS' | localize }}</span>
                            </a>
                        </li>
                        <li [class.active]="navSectionService.isEventsActive$ | async"
                            *dsPermissionsRequired="'any: EVENT_VIEW, SAVED_SEARCH_VIEW, CLASSIFIER_VIEW'"
                            test-id="main-events-container">
                            <a class="navbar-item"
                               [matTooltip]="'EVENTS' | localize"
                               [matTooltipDisabled]="leftBarVisible"
                               matTooltipPosition="right"
                               [dsRouterLink]="sectionLinks['EVENTS']"
                               test-id="module-events-description">
                                <i class="fal fa-fw fa-laptop"></i>
                                <span *ngIf="leftBarVisible">{{ 'EVENTS' | localize }}</span>
                            </a>
                        </li>
                        <li [class.active]="navSectionService.isAssetsActive$ | async"
                            *dsPermissionsRequired="'ASSET_VIEW'"
                            test-id="main-assets-container">
                            <a class="navbar-item"
                               [matTooltip]="'ASSETS' | localize"
                               [matTooltipDisabled]="leftBarVisible"
                               matTooltipPosition="right"
                               [dsRouterLink]="sectionLinks['ASSETS']">
                                <i class="fal fa-fw fa-cubes"></i>
                                <span *ngIf="leftBarVisible">{{ 'ASSETS' | localize }}</span>
                            </a>
                        </li>
                    </ul>
                    <hr/>
                    <ul class="navbar-section">
                        <li [class.active]="navSectionService.isFraudActive$ | async"
                            *dsPermissionsRequired="'any: ALERT_INBOX_VIEW, TRIGGER_VIEW, THREATMATCH_VIEW'"
                            test-id="main-fraud-container">
                            <a class="navbar-item"
                               [matTooltip]="'FRAUD' | localize"
                               [matTooltipDisabled]="leftBarVisible"
                               matTooltipPosition="right"
                               [dsRouterLink]="sectionLinks['FRAUD']">
                                <i class="fal fa-fw fa-user-secret"></i>
                                <span *ngIf="leftBarVisible">{{ 'FRAUD' | localize }}</span>
                            </a>
                        </li>
                        <li [class.active]="(navSectionService.isAlertsActive$ | async)"
                            *dsPermissionsRequired="'any: ALERT_INBOX_VIEW, TRIGGER_VIEW, THREATMATCH_VIEW'"
                            test-id="main-alerts-container">
                            <a class="navbar-item"
                               [matTooltip]="'THREAT_SURVEILLANCE' | localize"
                               [matTooltipDisabled]="leftBarVisible"
                               matTooltipPosition="right"
                               [dsRouterLink]="sectionLinks['THREAT_SURVEILLANCE']">
                                <i class="fal fa-fw fa-shield-halved"></i>
                                <span *ngIf="leftBarVisible">{{ 'THREAT_SURVEILLANCE' | localize }}</span>
                            </a>
                        </li>
                        <li [class.active]="navSectionService.isComplianceActive$ | async"
                            *dsPermissionsRequired="'any: TASK_VIEW, TASK_SCHEDULE_VIEW, POLICY_FRAMEWORK_VIEW, CUSTOM_POLICY_VIEW, CAT_POLICY_VIEW'"
                            test-id="main-compliance-container">
                            <a class="navbar-item"
                               [matTooltip]="'GOVERNANCE' | localize"
                               [matTooltipDisabled]="leftBarVisible"
                               matTooltipPosition="right"
                               [dsRouterLink]="sectionLinks['GOVERNANCE']"
                               [queryParams]="sectionQueryParams['GOVERNANCE']">
                                <i class="fal fa-fw fa-scale-unbalanced"></i>
                                <span *ngIf="leftBarVisible">{{ 'GOVERNANCE' | localize }}</span>
                            </a>
                        </li>
                        <li [class.active]="navSectionService.isRiskActive$ | async"
                            *dsPermissionsRequired="'RISK_VIEW'"
                            test-id="main-risk-container">
                            <a class="navbar-item"
                               [matTooltip]="'RISK' | localize"
                               [matTooltipDisabled]="leftBarVisible"
                               matTooltipPosition="right"
                               [dsRouterLink]="sectionLinks['RISK']">
                                <i class="fal fa-fw fa-gauge-simple-low"></i>
                                <span *ngIf="leftBarVisible">{{ 'RISK' | localize }}</span>
                            </a>
                        </li>
                    </ul>
                    <hr/>
                    <ul class="navbar-section">
                        <li [class.active]="navSectionService.isSettingsActive$ | async"
                            test-id="main-settings-container">
                            <a class="navbar-item"
                               [matTooltip]="'SETTINGS' | localize"
                               [matTooltipDisabled]="leftBarVisible"
                               matTooltipPosition="right"
                               [dsRouterLink]="sectionLinks['SETTINGS']">
                                <i class="fal fa-fw fa-gear"></i>
                                <span *ngIf="leftBarVisible">{{ 'SETTINGS' | localize }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    `,
    styles: `
        @import "/src/styles/colors";
        @import "/src/styles/settings";

        .left-bar {
            height: 100%;

            &:hover .navbar-toggle-button {
                visibility: visible;
            }
        }

        .left-bar-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 24px 0;
        }

        .header-container {
            display: flex;
            padding: 0 24px;
        }

        .navbar-toggle-button {
            visibility: hidden;
            position: absolute;
            right: -14px;
            cursor: pointer;
            font-size: 28px;
            color: $ds-blue-6;

            &:hover {
                color: $ds-blue-4;
            }

            i {
                line-height: $nav-header-org-badge-height;
            }
        }

        .navbar-section-container {
            margin-top: 36px;
            padding: 0 24px;
            overflow-y: auto;
            max-height: 832px;

            li.active .navbar-item {
                color: $ds-blue-6;
            }

            .navbar-section {
                display: flex;
                flex-direction: column;
                gap: 4px;
                margin: unset; // Removing Foundation's CSS overrides
                list-style-type: none;
            }

            .navbar-item {
                display: flex;
                cursor: pointer;
                height: 48px;
                padding: 16px;
            }

            &.left-bar-expanded {
                .navbar-item {
                    align-items: baseline;
                    gap: 8px;

                    &:hover {
                        border-radius: 4px;
                        background-color: $ds-blue-1;
                    }

                    span {
                        white-space: nowrap;
                        line-height: 1rem;
                        font-size: 14px;
                    }
                }
            }

            &.left-bar-collapsed {
                .navbar-item {
                    justify-content: center;
                    align-items: center;
                }

                i {
                    font-size: 1.25rem;
                }

                li:hover {
                    color: $ds-blue-7;
                }
            }
        }

        hr {
            margin: 26px 0;
            border-width: 1px 0 0;
        }
    `,
    animations: [
        trigger("toggleMenu", [
            state("true", style({ width: "15.625rem" })),
            state("false", style({ width: "5.5rem" })),
            transition("* => *", [
                group([
                    animate("150ms ease-in"),
                    query("@toggleContent", animateChild()),
                ]),
            ]),
        ]),
        trigger("toggleContent", [
            transition("false => true", animate("150ms", keyframes([
                style({ display: "none", offset: 0 }),
            ]))),
        ]),
    ],
})
export class NavigationLeftBarComponent implements OnInit {
    @Input() leftBarVisible: boolean;
    @Output() toggleLeftBar = new EventEmitter<void>();

    hasRiskSku: boolean;
    hasFraudSku: boolean;
    hasNewTabNavEnabled: boolean;
    currentPermissions: string[];

    sectionLinks = <Record<NavbarSection, string | string[]>>{};
    sectionQueryParams = <Record<NavbarSection, Params>>{};

    constructor(protected navSectionService: ActiveNavSectionService,
                private orgsService: OrgsService,
                private userService: UserService,
                private featuresService: FeaturesService) {
    }

    ngOnInit(): void {
        const orgSkus = this.orgsService.getCurrentOrg().productSkus;
        this.hasRiskSku = hasAnyRiskSku(orgSkus);
        this.hasFraudSku = hasAnyFraudSku(this.orgsService.getCurrentOrg().productSkus);

        combineLatest([
            this.userService.getCurrentPermissions$(),
            this.featuresService.getFeature$("newTabNav")
        ]).pipe(untilDestroyed(this))
            .subscribe(([perms, isEnabled]) => {
                this.currentPermissions = perms;
                this.hasNewTabNavEnabled = isEnabled;
                this.updateLinks();
            });
    }

    private updateLinks() {
        for (let section of allSections) {
            const { path, queryParams } = this.getSectionLink(section);
            this.sectionLinks[section] = path;
            this.sectionQueryParams[section] = queryParams;
        }
    }

    private getSectionLink(section: NavbarSection): { path: string | string[], queryParams?: Params } {
        switch (section) {
            case "HOME":
                return { path: ["/home"] };
            case "DASHBOARDS":
                return { path: ["/dashboards/default"] };
            case "REPORTS":
                if (this.currentPermissions.includes("REPORT_VIEW")) {
                    return { path: ["/reports"] };
                } else {
                    return { path: ["/reports/charts/list"] };
                }
            case "TICKETS":
                return { path: ["/tickets/incident"] };
            case "EVENTS":
                if (this.currentPermissions.includes("EVENT_VIEW")) {
                    return { path: ["/search"] };
                } else if (this.currentPermissions.includes("SAVED_SEARCH_VIEW")) {
                    return { path: ["/search/saved"] };
                } else {
                    return { path: ["/classifiers"] };
                }
            case "ASSETS":
                return { path: ["/assets"] };
            case "FRAUD":
                if (!this.hasFraudSku) {
                    return { path: ["/fraud/offering"] };
                } else if (this.currentPermissions.includes("ALERT_INBOX_VIEW")) {
                    return { path: ["/fraud/inbox"] };
                } else if (this.currentPermissions.includes("TRIGGER_VIEW")) {
                    return { path: ["/fraud/triggers"] };
                } else {
                    return { path: ["/fraud/threat"] };
                }
            case "THREAT_SURVEILLANCE":
                if (this.currentPermissions.includes("ALERT_INBOX_VIEW")) {
                    return { path: ["/alerts/inbox"] };
                } else if (this.currentPermissions.includes("TRIGGER_VIEW")) {
                    return { path: ["/triggers"] };
                } else {
                    return { path: ["/threat"] };
                }
            case "GOVERNANCE":
                if (this.currentPermissions.includes("TASK_VIEW")) {
                    return { path: ["/governance/tasks"] };
                } else if (this.currentPermissions.includes("TASK_SCHEDULE_VIEW")) {
                    return { path: ["/task-schedules"] };
                } else if (this.currentPermissions.includes("POLICY_FRAMEWORK_VIEW")) {
                    return { path: ["/governance/frameworks"], queryParams: { canRedirect: true } };
                } else if (this.currentPermissions.includes("CUSTOM_POLICY_VIEW")) {
                    return { path: ["/governance/policies"] };
                } else {
                    return { path: ["/governance/cat"] };
                }
            case "RISK":
                if (this.hasRiskSku && this.hasNewTabNavEnabled) {
                    return { path: ["/risk/assessments"] };
                } else if (this.hasRiskSku) {
                    return { path: ["/risk/risks/register"] };
                } else {
                    return { path: ["/risk/risks/library"] };
                }
            case "SETTINGS":
                if (this.currentPermissions.includes("USER_VIEW")) {
                    return { path: ["/settings/users"] };
                } else if (this.currentPermissions.includes("AUTH_TOKEN_VIEW")) {
                    return { path: ["/settings/input-tokens"] };
                } else if (this.currentPermissions.includes("INTEGRATION_VIEW")) {
                    return { path: ["/settings/integrations"] };
                } else if (this.currentPermissions.includes("DATA_RESTRICTION_VIEW")) {
                    return { path: ["/settings/data-restrictions"] };
                } else {
                    return { path: ["/settings/profile"] };
                }
            default:
                throw `Unknown section: ${ section }`;
        }
    }
}
