import { Component } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { UserService } from "@app2/account/user.service";
import { DashboardsService } from "@app2/dashboards/dashboards.service";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { FeaturesService } from "@app2/shared/services/features.service";
import { RouterService } from "@app2/shared/services/router.service";
import { StorageService } from "@app2/shared/services/storage.service";
import { DashboardTemplate } from "@app2/type-defs/report/report-types";
import { StorageServiceKeys } from "@app2/util/storage-service-keys";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, Observable } from "rxjs";
import { take } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "navigation-top-bar-dashboard",
    template: `
        <nav *ngIf="hasNewTabNavEnabled"
             class="top-bar new-tab-nav" role="navigation">
            <section class="top-bar-section nav-section-main">
                <span class="top-bar-title">
                    <h1 class="nav-top-item-main">{{ "DASHBOARDS" | localize }}</h1>
                    <ds-button type="black-link" id="new-dashboards-beta"
                               (click)="toggleNewDashboardsBeta()">
                        <i class="fa fa-toggle-large-off secondary" *ngIf="!revealEnabled"></i>
                        <i class="fa fa-toggle-large-on primary" *ngIf="revealEnabled"></i>
                        {{ "NEW_DASHBOARDS_BETA" | localize }}
                    </ds-button>
                </span>
                <ul class="left">
                    <ng-container *ngIf="!revealEnabled">
                        <ng-container *dsPermissionsRequired="'DASHBOARD_VIEW'">
                            <ng-container *ngIf="!loading">
                                <li *ngIf="(navSectionService.isOldMainDashboardActive$ | async) === false"
                                    test-id="current-dashboard-name">
                                    <a dsRouterLink="/dashboard">{{ (selectedDashboard$ | async).name | abbreviate:nameCharLimit }}</a>
                                </li>
                                <li [class.active]="navSectionService.isOldDashboardActive$ | async"
                                    *ngIf="navSectionService.isOldMainDashboardActive$ | async"
                                    test-id="current-dashboard-name">
                                    <pr-dropdown [showOnHover]="true"
                                                 *ngIf="allDashboards$ | async as allDashboards">
                                        <div pr-dropdown-trigger>
                                        <span dsRouterLink="/dashboard" class="dropdown-value">
                                            {{ (selectedDashboard$ | async).name | abbreviate:nameCharLimit }}
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                        </div>
                                        <ul pr-dropdown-body class="overflow-auto no-display-inline navbar-dropdown">
                                            <li class="dropdown-item-full-width"
                                                *ngFor="let dashboard of allDashboards">
                                                <a (click)="changeSelectedDashboard(dashboard)">
                                                    {{ dashboard.name | abbreviate:nameCharLimit }}
                                                </a>
                                            </li>
                                        </ul>
                                    </pr-dropdown>
                                </li>
                            </ng-container>
                        </ng-container>
                        <li [class.active]="navSectionService.isOldDashboardActive$ | async"
                            *dsPermissionsRequired="'DASHBOARD_VIEW'"
                            test-id="tab-dashboards-list">
                            <a dsRouterLink="/dashboard/template/list">{{ 'OVERVIEW' | localize }}</a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="revealEnabled">
                        <li [class.active]="navSectionService.isDefaultDashboardActive$ | async"
                            *dsPermissionsRequired="'DASHBOARD_VIEW'">
                            <a dsRouterLink="/dashboards/default">{{ "OVERVIEW" | localize }}</a>
                        </li>
                        <li [class.active]="navSectionService.isDashboardsListActive$ | async"
                            *dsPermissionsRequired="'DASHBOARD_VIEW'">
                            <a [dsRouterLink]="workspaceTilesPageEnabled ? '/dashboards/workspace-tiles-page'
                                                                             : '/dashboards'"
                               [attr.id]="'directory-tab-' + workspaceTilesPageEnabled ? 'workspace-tiles' : 'dashboards'">
                                {{ 'REVEAL_DASHBOARDS' | localize }}
                            </a>
                        </li>
                        <li [class.active]="navSectionService.isLibraryDashboardsActive$ | async"
                            *dsPermissionsRequired="'DASHBOARD_VIEW'">
                            <a [dsRouterLink]="workspaceTilesPageEnabled ? '/dashboards/library/workspace-tiles-page'
                                                                         : '/dashboards/library'">
                                {{ 'LIBRARY' | localize }}
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </section>
        </nav>
        <nav *ngIf="!hasNewTabNavEnabled"
             class="top-bar" role="navigation">
            <section class="old-tab-nav top-bar-section nav-section-main">
                <ul class="left" [class.extra-margin-top]="revealEnabled">
                    <ng-container *ngIf="!revealEnabled">
                        <ng-container *dsPermissionsRequired="'DASHBOARD_VIEW'">
                            <li class="nav-top-item-main"
                                *ngIf="loading">
                                <a dsRouterLink="/dashboard">{{ "DASHBOARDS" | localize }}</a>
                            </li>
                            <ng-container *ngIf="!loading">
                                <li class="nav-top-item-main extra-margin-top"
                                    *ngIf="(navSectionService.isOldMainDashboardActive$ | async) === false"
                                    test-id="current-dashboard-name">
                                    <a dsRouterLink="/dashboard">{{ (selectedDashboard$ | async).name | abbreviate:nameCharLimit }}</a>
                                </li>
                                <li class="nav-top-item-main active"
                                    *ngIf="navSectionService.isOldMainDashboardActive$ | async"
                                    test-id="current-dashboard-name">
                                    <pr-dropdown class="old-tab-view"
                                                 [showOnHover]="true"
                                                 *ngIf="allDashboards$ | async as allDashboards">
                                        <div pr-dropdown-trigger>
                                        <span dsRouterLink="/dashboard" class="dropdown-value nav-top-item-main-text">
                                            {{ (selectedDashboard$ | async).name | abbreviate:nameCharLimit }}
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                        </div>
                                        <ul pr-dropdown-body class="overflow-auto no-display-inline navbar-dropdown">
                                            <li class="dropdown-item-full-width"
                                                *ngFor="let dashboard of allDashboards">
                                                <a (click)="changeSelectedDashboard(dashboard)">
                                                    {{ dashboard.name | abbreviate:nameCharLimit }}
                                                </a>
                                            </li>
                                        </ul>
                                    </pr-dropdown>
                                </li>
                            </ng-container>
                        </ng-container>
                        <li [class.active]="navSectionService.isOldDashboardActive$ | async"
                            *dsPermissionsRequired="'DASHBOARD_VIEW'"
                            test-id="tab-dashboards-list">
                            <a dsRouterLink="/dashboard/template/list">{{ 'DASHBOARDS' | localize }}</a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="revealEnabled">
                        <li class="nav-top-item-main"
                            [class.active]="navSectionService.isDefaultDashboardActive$ | async"
                            *dsPermissionsRequired="'DASHBOARD_VIEW'">
                            <a dsRouterLink="/dashboards/default">{{ "DEFAULT_DASHBOARD" | localize }}</a>
                        </li>
                        <li [class.active]="navSectionService.isDashboardsListActive$ | async"
                            *dsPermissionsRequired="'DASHBOARD_VIEW'">
                            <a [dsRouterLink]="workspaceTilesPageEnabled ? '/dashboards/workspace-tiles-page'
                                                                             : '/dashboards'"
                               [attr.id]="'directory-tab-' + workspaceTilesPageEnabled ? 'workspace-tiles' : 'dashboards'">
                                {{ 'REVEAL_DASHBOARDS' | localize }}
                            </a>
                        </li>
                        <li [class.active]="navSectionService.isLibraryDashboardsActive$ | async"
                            *dsPermissionsRequired="'DASHBOARD_VIEW'">
                            <a [dsRouterLink]="workspaceTilesPageEnabled ? '/dashboards/library/workspace-tiles-page'
                                                                         : '/dashboards/library'">
                                {{ 'LIBRARY' | localize }}
                            </a>
                        </li>
                    </ng-container>
                    <li>
                        <a id="new-dashboards-beta"
                           (click)="toggleNewDashboardsBeta()">
                            <i class="fa fa-toggle-large-off secondary" *ngIf="!revealEnabled"></i>
                            <i class="fa fa-toggle-large-on primary" *ngIf="revealEnabled"></i>
                            {{ "NEW_DASHBOARDS_BETA" | localize }}
                        </a>
                    </li>
                </ul>
            </section>
        </nav>
    `,
    styles: [`
      @import "/src/styles/colors";

      .new-tab-nav {
        .top-bar-title {
          display: flex;

          ds-button {
            margin: 18px 0 0 24px;
          }
        }

        pr-dropdown {
          font-size: 0.9375rem;

          div {
            height: 35px;

            &:before {
               content: "";
               position: absolute;
               bottom: 0;
               left: 0;
               right: 0;
               height: 4px;
               background-color: $ds-blue-6;
            }
          }
        }
      }

      section.old-tab-nav {
        display: flex;
        align-items: center;
      }

      ul {
        display: flex;
      }

      .nav-top-item-main-text {
        white-space: nowrap;
      }

      #new-dashboards-beta {
        font-size: 12px;
        white-space: nowrap;
      }

      pr-dropdown.old-tab-view div {
        height: 56px;

        &:before {
         content: "";
         position: absolute;
         bottom: 0;
         left: 0;
         right: 0;
         height: 4px;
         width: 32px;
         background-color: $ds-blue-6;
        }
      }
    `],
})
export class NavigationTopBarDashboardComponent {
    nameCharLimit = 50;
    selectedDashboard$: Observable<DashboardTemplate>;
    allDashboards$: Observable<DashboardTemplate[]>;
    loading: boolean;

    revealEnabled: boolean;
    workspaceTilesPageEnabled: boolean;
    hasNewTabNavEnabled: boolean;

    constructor(private dashboardsService: DashboardsService,
                public navSectionService: ActiveNavSectionService,
                private router: RouterService,
                private storageService: StorageService,
                private userService: UserService,
                private orgsService: OrgsService,
                private featuresService: FeaturesService) {
        this.allDashboards$ = this.dashboardsService.getDashboards$();
        this.selectedDashboard$ = this.dashboardsService.getSelectedDashboard$();

        this.loading = true;
        this.selectedDashboard$
            .pipe(take(1))
            .subscribe(() => this.loading = false);

        this.featuresService.getFeature$("workspaceTilesPage")
            .pipe(untilDestroyed(this))
            .subscribe(enabled => this.workspaceTilesPageEnabled = enabled);

        let futures = [
            this.userService.getCurrentUser$(),
            this.orgsService.getCurrentOrg$(),
            this.featuresService.getFeature$("defaultRevealDashboards")
        ];

        combineLatest(futures)
            .subscribe(([user, org, featureEnabled]) => {
                // Wait for StorageService dependencies to be available
                if (user && org) {
                    this.revealEnabled = featureEnabled && !this.storageService.keyExists(StorageServiceKeys.useNewDashboards)
                        ? true
                        : !!this.storageService.get(StorageServiceKeys.useNewDashboards);
                    this.storageService.set(StorageServiceKeys.useNewDashboards, this.revealEnabled);
                }
            });

        this.featuresService.getFeature$("newTabNav")
            .pipe(untilDestroyed(this))
            .subscribe(isEnabled => {
                this.hasNewTabNavEnabled = isEnabled;
                document.documentElement.style.setProperty("--nav-top-bar-height",
                    isEnabled ? "5.75rem" : "3.5rem");
            });
    }

    changeSelectedDashboard(dashboard: DashboardTemplate) {
        this.dashboardsService.setSelectedDashboard(dashboard);
    }

    toggleNewDashboardsBeta(): void {
        this.revealEnabled = !this.revealEnabled;
        this.storageService.set(StorageServiceKeys.useNewDashboards, this.revealEnabled);
        this.router.navigate([this.revealEnabled ? "/dashboards/default" : "/dashboard"]);
    }
}
