import { Component, OnInit } from "@angular/core";
import { RiskService } from "@app2/risk/risk-shared/risk.service";
import { RiskModuleType } from "@app2/type-defs/risk/risk-types";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { FeaturesService } from "@app2/shared/services/features.service";

export interface RiskModuleInfo {
    moduleType: RiskModuleType;
    name: string;
    description: string;
    badgeIcon: string;
}

const riskModuleItems: RiskModuleInfo[] = [
    {
        moduleType: RiskModuleType.INFORMATION_SECURITY,
        name: "RISK_MODULE_INFORMATION_SECURITY",
        description: "RISK_MODULE_INFORMATION_SECURITY_DESCRIPTION",
        badgeIcon: "assets/icons/risk-module-icon-is.svg",
    },
    {
        moduleType: RiskModuleType.SYSTEMS_AND_APPLICATIONS,
        name: "RISK_MODULE_SYSTEMS_AND_APPLICATIONS",
        description: "RISK_MODULE_SYSTEMS_AND_APPLICATIONS_DESCRIPTION",
        badgeIcon: "assets/icons/risk-module-icon-sa.svg",
    },
    {
        moduleType: RiskModuleType.THIRD_PARTY_RISK,
        name: "RISK_MODULE_THIRD_PARTY_RISK",
        description: "RISK_MODULE_THIRD_PARTY_RISK_DESCRIPTION",
        badgeIcon: "assets/icons/risk-module-icon-tp.svg",
    },
];

@UntilDestroy()
@Component({
    selector: "navbar-risk-menu",
    template: `
        <ng-container *ngIf="hasNewTabNavEnabled">
            <div class="new-tab-nav risk-badges-container">
                <svg-icon *ngFor="let module of riskModuleItems"
                          [inline]="true"
                          [src]="module.badgeIcon"
                          [matTooltip]="module.description | localize"
                          [ngClass]="isSelectedModule(module.moduleType) ? 'module-selected' : ''"
                          [class.module-purchased]="purchasedModules.includes(module.moduleType)"
                          (click)="selectModule(module.moduleType)"
                          class="risk-module-icon"
                          [test-class]="purchasedModules.includes(module.moduleType) ? 'purchased-badge' : 'nonpurchased-badge'">
                </svg-icon>
            </div>
        </ng-container>
        <pr-dropdown *ngIf="!hasNewTabNavEnabled"
                     class="risk-modules-dropdown">
            <div pr-dropdown-trigger>
                {{ "RISK" | localize }}
                <div class="risk-badges-container">
                    <svg-icon *ngFor="let module of purchasedModules"
                              [inline]="true"
                              [src]="getRiskModuleItem(module).badgeIcon"
                              [ngClass]="isSelectedModule(module) ? 'module-selected' : ''"
                              class="module-purchased risk-module-icon"
                              test-class="purchased-badge">
                    </svg-icon>
                    <svg-icon *ngFor="let module of nonPurchasedModules"
                              [inline]="true"
                              [src]="getRiskModuleItem(module).badgeIcon"
                              [ngClass]="isSelectedModule(module) ? 'module-selected' : ''"
                              class="risk-module-icon"
                              test-class="nonpurchased-badge">
                    </svg-icon>
                </div>
                <i class="far fa-angle-down"></i>
            </div>
            <ul pr-dropdown-body>
                <li *ngFor="let module of purchasedModules; let first = first;"
                    [class.active]="isSelectedModule(module)"
                    test-class="purchased-module">
                    <span *ngIf="first"
                          (click)="selectAllModules()"
                          class="module-header"
                          test-id="all-modules-checkbox">
                        <div class="checkbox-container">
                            <i class="checkbox far fa-check-square" *ngIf="isMultiSelected === 'all'"></i>
                            <i class="checkbox far fa-square-minus" *ngIf="isMultiSelected === 'some'"></i>
                            <i class="checkbox far fa-square" *ngIf="isMultiSelected === 'none'"></i>
                        </div>
                        {{ "YOUR_MODULES" | localize | uppercase }}
                    </span>
                    <div (click)="selectModule(module)"
                         test-class="module-container">
                        <span class="risk-module-title"
                              test-class="module-title">
                            <div class="checkbox-container">
                                <i class="checkbox far"
                                   [ngClass]="isSelectedModule(module) ? 'fa-check-square' : 'fa-square'"></i>
                            </div>
                            {{ getRiskModuleItem(module).name | localize }}
                        </span>
                        <span class="risk-module-description"
                              test-class="module-description">
                            {{ getRiskModuleItem(module).description | localize }}
                        </span>
                    </div>
                </li>
                <li *ngFor="let module of nonPurchasedModules; let first = first;"
                    [class.active]="isSelectedModule(module)"
                    test-class="nonpurchased-module">
                    <span *ngIf="purchasedModules.length > 0 && first"
                          class="module-header">
                        {{ "OTHER_MODULES" | localize | uppercase }}
                    </span>
                    <div (click)="selectModule(module)"
                         test-class="module-container">
                        <span class="risk-module-title"
                              test-class="module-title">
                            {{ getRiskModuleItem(module).name | localize }}
                            <i class="checkmark far fa-check"></i>
                        </span>
                        <span class="risk-module-description"
                              test-class="module-description">
                            {{ getRiskModuleItem(module).description | localize }}
                        </span>
                    </div>
                </li>
            </ul>
        </pr-dropdown>
    `,
    styles: [`
      .new-tab-nav svg-icon {
        cursor: pointer;
      }

      .fa-angle-down {
        font-size: 1.5rem;
      }
    `],
})
export class NavbarRiskMenuComponent implements OnInit {
    isMultiSelected: ("all" | "some" | "none") = "some";
    purchasedModules: RiskModuleType[];
    nonPurchasedModules: RiskModuleType[];
    selectedModules: RiskModuleType[];
    hasNewTabNavEnabled: boolean;
    riskModuleItems = riskModuleItems;

    constructor(private riskService: RiskService,
                private featuresService: FeaturesService) {
        this.purchasedModules = this.riskService.getPurchasedModules();
        this.nonPurchasedModules = this.riskService.getNonPurchasedModules();

        this.featuresService.getFeature$("newTabNav")
            .pipe(untilDestroyed(this))
            .subscribe(isEnabled => {
                this.hasNewTabNavEnabled = isEnabled;
            });
    }

    ngOnInit() {
        this.riskService.getSelectedModules$()
            .pipe(untilDestroyed(this))
            .subscribe(modules => {
                this.selectedModules = modules;
            });
    }

    selectModule(module: RiskModuleType): void {
        this.riskService.selectModule(module, !this.isSelectedModule(module));
        this.isMultiSelected = this.nonPurchasedModules.includes(module) ? "none" :
            (this.selectedModules.length === this.purchasedModules.length ? "all" : "some");
    }

    selectAllModules(): void {
        this.riskService.selectAllModules();
        this.isMultiSelected = this.selectedModules.length === this.purchasedModules.length ? "all" : "some";
    }

    isSelectedModule(module: RiskModuleType): boolean {
        return this.selectedModules.includes(module);
    }

    getRiskModuleItem(module: RiskModuleType): RiskModuleInfo {
        return riskModuleItems.find((item) => item.moduleType === module);
    }
}
