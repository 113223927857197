import { Component } from "@angular/core";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { FeaturesService } from "@app2/shared/services/features.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Observable } from "rxjs";

@UntilDestroy()
@Component({
    selector: "navigation-top-bar-home",
    template: `
        <nav *ngIf="hasNewTabNavEnabled$ | async"
             class="top-bar new-tab-nav no-bottom-border" role="navigation">
            <section class="top-bar-section nav-section-main">
                <h1 class="nav-top-item-main">{{ "CYBER_RISK_READINESS_DASHBOARD" | localize }}</h1>
            </section>
        </nav>
        <nav *ngIf="(hasNewTabNavEnabled$ | async) === false"
             class="top-bar no-border" role="navigation">
            <section class="top-bar-section nav-section-main">
                <ul class="left extra-margin-top">
                    <li class="nav-top-item-main main-item-no-link">
                        {{ "CYBER_RISK_READINESS_DASHBOARD" | localize }}
                    </li>
                </ul>
            </section>
        </nav>
    `,
})
export class NavigationTopBarHomeComponent {
    hasNewTabNavEnabled$: Observable<boolean>;

    constructor(public navSectionService: ActiveNavSectionService,
                private featuresService: FeaturesService) {
        this.hasNewTabNavEnabled$ = this.featuresService.getFeature$("newTabNav");
        document.documentElement.style.setProperty("--nav-top-bar-height", "3rem");
    }
}
