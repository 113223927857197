import { Component } from "@angular/core";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { UserService } from "@app2/account/user.service";
import { Observable } from "rxjs";
import { FeaturesService } from "@app2/shared/services/features.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "navigation-top-bar-policy",
    template: `
        <nav *ngIf="hasNewTabNavEnabled"
             class="top-bar new-tab-nav" role="navigation">
            <section class="top-bar-section nav-section-main">
                <h1 class="nav-top-item-main">{{ "GOVERNANCE" | localize }}</h1>
                <ul class="left">
                    <li [class.active]="navSectionService.isTasksActive$ | async"
                        *dsPermissionsRequired="'TASK_VIEW'"
                        id="tasks-tab">
                        <a dsRouterLink="/governance/tasks">
                            {{ 'TASKS' | localize }}
                        </a></li>
                    <li [class.active]="navSectionService.isTaskSchedulesActive$ | async"
                        *dsPermissionsRequired="'TASK_SCHEDULE_VIEW'"
                        id="task-schedules-tab">
                        <a dsRouterLink="/task-schedules">{{ "TASK_SCHEDULES" | localize }}</a></li>
                    <li [class.active]="navSectionService.isLibraryTaskSchedulesActive$ | async"
                        *dsPermissionsRequired="'TASK_SCHEDULE_VIEW'"
                        id="library-task-schedules-tab">
                        <a dsRouterLink="/library-task-schedules">{{ "LIBRARY_TASK_SCHEDULES_TAB" | localize }}</a></li>
                    <li [class.active]="navSectionService.isFrameworksActive$ | async"
                        *dsPermissionsRequired="'POLICY_FRAMEWORK_VIEW'">
                        <a dsRouterLink="/governance/frameworks"
                           [queryParams]="{canRedirect: true}">{{ "POLICY_FRAMEWORKS" | localize }}</a></li>
                    <li [class.active]="navSectionService.isCustomPoliciesActive$ | async"
                        *dsPermissionsRequired="'CUSTOM_POLICY_VIEW'">
                        <a dsRouterLink="/governance/policies">{{ "POLICY_POLICIES" | localize }}</a></li>
                    <li [class.active]="navSectionService.isCATPoliciesActive$ | async"
                        *dsPermissionsRequired="'CAT_POLICY_VIEW'">
                        <a dsRouterLink="/governance/cat">{{ "FFIEC_CAT" | localize }}</a></li>
                </ul>
            </section>
        </nav>
        <nav *ngIf="!hasNewTabNavEnabled"
             class="top-bar" role="navigation">
            <section class="top-bar-section nav-section-main">
                <ul class="left extra-margin-top">
                    <li class="nav-top-item-main">
                        <a [dsRouterLink]="(hasFrameworkPerms$ | async) ? '/governance/frameworks' : '/governance/policies'">
                            {{"GOVERNANCE" | localize}}</a></li>
                    <li [class.active]="navSectionService.isTasksActive$ | async"
                        *dsPermissionsRequired="'TASK_VIEW'"
                        id="tasks-tab">
                        <a dsRouterLink="/governance/tasks">
                            {{ 'TASKS' | localize }}
                        </a></li>
                    <li [class.active]="navSectionService.isTaskSchedulesActive$ | async"
                        *dsPermissionsRequired="'TASK_SCHEDULE_VIEW'"
                        id="task-schedules-tab">
                        <a dsRouterLink="/task-schedules">{{ "TASK_SCHEDULES" | localize }}</a></li>
                    <li [class.active]="navSectionService.isLibraryTaskSchedulesActive$ | async"
                        *dsPermissionsRequired="'TASK_SCHEDULE_VIEW'"
                        id="library-task-schedules-tab">
                        <a dsRouterLink="/library-task-schedules">{{ "LIBRARY_TASK_SCHEDULES_TAB" | localize }}</a></li>
                    <li [class.active]="navSectionService.isFrameworksActive$ | async"
                        *dsPermissionsRequired="'POLICY_FRAMEWORK_VIEW'">
                        <a dsRouterLink="/governance/frameworks"
                           [queryParams]="{canRedirect: true}">{{"POLICY_FRAMEWORKS" | localize}}</a></li>
                    <li [class.active]="navSectionService.isCustomPoliciesActive$ | async"
                        *dsPermissionsRequired="'CUSTOM_POLICY_VIEW'">
                        <a dsRouterLink="/governance/policies">{{"POLICY_POLICIES" | localize}}</a></li>
                    <li [class.active]="navSectionService.isCATPoliciesActive$ | async"
                        *dsPermissionsRequired="'CAT_POLICY_VIEW'">
                        <a dsRouterLink="/governance/cat">{{"FFIEC_CAT" | localize}}</a></li>
                </ul>
            </section>
        </nav>
    `,
})
export class NavigationTopBarPolicyComponent {
    hasFrameworkPerms$: Observable<boolean>;
    hasNewTabNavEnabled: boolean;

    constructor(public navSectionService: ActiveNavSectionService,
                private userService: UserService,
                private featuresService: FeaturesService) {
        this.hasFrameworkPerms$ = this.userService.hasPermission$("POLICY_FRAMEWORK_VIEW");
        this.featuresService.getFeature$("newTabNav")
            .pipe(untilDestroyed(this))
            .subscribe(isEnabled => {
                this.hasNewTabNavEnabled = isEnabled;
                document.documentElement.style.setProperty("--nav-top-bar-height",
                    isEnabled ? "5.75rem" : "3.5rem");
            });
    }
}
