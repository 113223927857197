import { Component } from "@angular/core";
import { EnvironmentService } from "@app2/shared/services/environment.service";

@Component({
    selector: "app-version",
    template: `
        <span [hidden]="hideVersion" class="build-status">
            {{ this.version }} [{{ this.environment }}]
        </span>
    `,
})
export class AppVersionComponent {

    version: string;
    environment: string;
    hideVersion: boolean;

    constructor(envService: EnvironmentService) {
        this.version = envService.getConfig().appVersion;
        this.environment = envService.getConfig().envName;
        this.hideVersion = envService.getConfig().hideAppVersion;
    }
}
