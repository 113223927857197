import { Component } from "@angular/core";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { RecentTicketsService } from "@app2/shared/services/recent-tickets.service";
import { TicketSummary } from "@app2/type-defs/ticket/ticket-types";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { FeaturesService } from "@app2/shared/services/features.service";

@UntilDestroy()
@Component({
    selector: "navigation-top-bar-tickets",
    template: `
        <nav *ngIf="hasNewTabNavEnabled"
             class="top-bar new-tab-nav" role="navigation">
            <section class="top-bar-section nav-section-main">
                <h1 class="nav-top-item-main">{{ "BASE_TICKETS" | localize }}</h1>
                <ul class="left">
                    <li [class.active]="navSectionService.isIncidentsActive$ | async"
                        *dsPermissionsRequired="'INCIDENT_VIEW'">
                        <a dsRouterLink="/tickets/incident">
                            {{ 'INCIDENTS' | localize }}
                        </a>
                    </li>
                    <ng-container *featureGate="'universalTicketInbox'">
                        <li [class.active]="navSectionService.isInboxIncidentsActive$ | async">
                            <a dsRouterLink="/universal-ticket-inbox/incident">
                                {{ "GLOBAL_INCIDENTS_TAB" | localize }}
                            </a>
                        </li>
                        <li [class.active]="navSectionService.isInboxTasksActive$ | async">
                            <a dsRouterLink="/universal-ticket-inbox/task">
                                {{ 'GLOBAL_TASKS_TAB' | localize }}
                            </a>
                        </li>
                        <ng-container *featureGate="'testInbox'">
                            <ng-container *dsPermissionsRequired="'TRAC_ENGINEER'">
                                <li [class.active]="navSectionService.isInboxDraftsActive$ | async">
                                    <a dsRouterLink="/universal-ticket-inbox/draft">
                                        {{ 'GLOBAL_DRAFTS_TAB' | localize }}
                                    </a>
                                </li>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *dsPermissionsRequired="'TRAC_USER'">
                        <li [class.active]="navSectionService.isTicketTemplateActive$ | async">
                            <a dsRouterLink="/ticket-templates">{{ "TICKET_TEMPLATES_TAB" | localize }}</a>
                        </li>
                    </ng-container>
                </ul>
            </section>
        </nav>
        <nav *ngIf="!hasNewTabNavEnabled"
             class="top-bar" role="navigation">
            <section class="top-bar-section nav-section-main">
                <ul class="left">
                    <li class="nav-top-item-main"
                        *dsPermissionsRequired="'any: INCIDENT_VIEW, TASK_VIEW'">
                        <pr-dropdown [showOnHover]="true"
                                     (popupShown)="refreshRecentTickets()">
                            <div pr-dropdown-trigger>
                                <span class="dropdown-value nav-top-item-main-text">
                                    {{ 'BASE_TICKETS' | localize }}
                                </span>
                            </div>
                            <ul pr-dropdown-body class="overflow-auto no-display-inline navbar-dropdown">
                                <li class="dropdown-fixed-text dropdown-item-full-width"
                                    *ngIf="recentTickets.length > 0"
                                    (click)="$event.stopPropagation()">
                                    {{ 'RECENT_TICKETS' | localize }}
                                </li>
                                <li class="dropdown-fixed-text dropdown-item-full-width"
                                    *ngIf="recentTickets.length === 0">
                                    {{ 'NO_RECENT_TICKETS' | localize }}
                                </li>
                                <li class="dropdown-item-full-width"
                                    *ngFor="let ticket of recentTickets"
                                    [id]="'recent-' + ticket.slugName + '-' + ticket.slugId">
                                    <a [dsRouterLink]="['/tickets', ticket.slugName, ticket.slugId]">
                                        {{ ticket.summary + ' (' + ticket.slugName + '/' + ticket.slugId + ')' }}</a>
                                </li>
                                <li class="divider" *ngIf="recentTickets.length > 0"></li>
                                <li class="dropdown-item-full-width"
                                    *ngIf="recentTickets.length > 0">
                                    <a (click)="clearRecentTickets()">
                                        {{ 'CLEAR_RECENT_TICKETS' | localize }}</a>
                                </li>
                            </ul>
                        </pr-dropdown>
                    </li>
                    <li [class.active]="navSectionService.isIncidentsActive$ | async"
                        *dsPermissionsRequired="'INCIDENT_VIEW'"
                        id="incidents-tab">
                        <a dsRouterLink="/tickets/incident">
                            {{ 'INCIDENTS' | localize }}
                        </a></li>
                    <ng-container *featureGate="'universalTicketInbox'">
                        <li [class.active]="navSectionService.isUniversalTicketInboxActive$ | async"
                            id="global-ticket-inbox-tab">
                            <a dsRouterLink="/universal-ticket-inbox/incident">{{ "GLOBAL_TICKET_INBOX_TAB" | localize }}</a>
                        </li>
                    </ng-container>
                    <ng-container *dsPermissionsRequired="'TRAC_USER'">
                        <li [class.active]="navSectionService.isTicketTemplateActive$ | async"
                            id="ticket-template-tab">
                            <a dsRouterLink="/ticket-templates">{{ "TICKET_TEMPLATES_TAB" | localize }}</a>
                        </li>
                    </ng-container>
                </ul>
            </section>
        </nav>
    `,
    styles: [`
        @import "/src/styles/colors";

        pr-dropdown div {
            height: 56px;

            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 4px;
                width: 32px;
                background-color: $ds-blue-6;
            }
        }
    `],
})
export class NavigationTopBarTicketsComponent {
    hasNewTabNavEnabled: boolean;
    recentTickets: TicketSummary[];

    constructor(public navSectionService: ActiveNavSectionService,
                private readonly recentTicketsService: RecentTicketsService,
                private featuresService: FeaturesService) {
        recentTicketsService.getRecentItems$()
            .pipe(untilDestroyed(this))
            .subscribe(tickets => this.recentTickets = tickets);

        this.featuresService.getFeature$("newTabNav")
            .pipe(untilDestroyed(this))
            .subscribe(isEnabled => {
                this.hasNewTabNavEnabled = isEnabled;
                document.documentElement.style.setProperty("--nav-top-bar-height",
                    isEnabled ? "5.75rem" : "3.5rem");
            });
    }

    clearRecentTickets() {
        this.recentTicketsService.clearRecentItems();
    }

    refreshRecentTickets() {
        this.recentTicketsService.refresh();
    }
}
