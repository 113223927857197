import { Component } from "@angular/core";

@Component({
    selector: "navigation-left-bar-no-auth",
    template: `
        <nav>
            <ul id="branding" class="left-navbar">
                <li>
                    <a href="https://www.defensestorm.com/">
                        <div class="branding-icon">
                            <svg-icon src="assets/icons/branding.svg"></svg-icon>
                        </div>
                    </a>
                </li>
            </ul>
        </nav>
    `,
})
export class NavigationLeftBarNoAuthComponent {
}
