import { Component } from "@angular/core";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { Observable } from "rxjs";
import { FeaturesService } from "@app2/shared/services/features.service";

@Component({
    selector: "navigation-top-bar-assets",
    template: `
        <nav *ngIf="hasNewTabNavEnabled$ | async"
             class="top-bar new-tab-nav no-bottom-border" role="navigation">
            <section class="top-bar-section nav-section-main">
                <h1 class="nav-top-item-main">{{ "BASE_ASSETS" | localize }}</h1>
            </section>
        </nav>
        <nav *ngIf="(hasNewTabNavEnabled$ | async) === false"
             class="top-bar" role="navigation">
            <section class="top-bar-section nav-section-main">
                <ul class="left assets-margin-top">
                    <li class="nav-top-item-main"
                        [class.active]="navSectionService.isAssetsListActive$ | async"
                        *dsPermissionsRequired="'ASSET_VIEW'">
                        <a dsRouterLink="/assets">{{ "BASE_ASSETS" | localize }}</a></li>
                </ul>
            </section>
        </nav>
    `,
})
export class NavigationTopBarAssetsComponent {
    hasNewTabNavEnabled$: Observable<boolean>;

    constructor(public navSectionService: ActiveNavSectionService,
                private featuresService: FeaturesService) {
        this.hasNewTabNavEnabled$ = this.featuresService.getFeature$("newTabNav");
        document.documentElement.style.setProperty("--nav-top-bar-height", "4rem");
    }
}
