import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";

@Component({
    selector: "search-box",
    template: `
        <form class="search-filter"
              [autocomplete]="autocomplete">
            <input type="text" name="query"
                   [(ngModel)]="searchTextCopy"
                   [placeholder]="placeholderKey | localize"
                   (keydown.enter)="submitForm()"
                   test-id="search-box-input"/>
            <button type="button" test-id="search-box-button"
                    (click)="submitForm()">
                <i class="far fa-search"></i>
            </button>
        </form>
    `,
    host: {
        style: "margin-left: auto;",
    },
})
export class SearchBoxComponent implements OnChanges {
    @Input() searchText: string = "";
    @Input() placeholderKey: string;
    @Input() autocomplete: string;

    @Output() searchTextChange = new EventEmitter<string>();

    searchTextCopy: string;

    ngOnChanges() {
        this.searchTextCopy = this.searchText;
    }

    submitForm() {
        this.searchTextChange.emit(this.searchTextCopy);
    }
}
