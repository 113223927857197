import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { DsSelectOption, DsSelectValue } from "@app2/shared/components/ds-select.component";
import { localization } from "@app2/shared/localization/localization";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { cloneDeep, keyBy } from "lodash-es";
import { DsDropdownComponent } from "@app2/shared/components/ds-dropdown.component";

@UntilDestroy()
@Component({
    selector: "ds-multi-select",
    template: `
        <ds-dropdown [showHeader]="false"
                     [showFooter]="savable"
                     [dropdownClass]="dropdownClass">
            <div id="ds-dropdown-trigger" class="dropdown-value" [class.value-selected]="anyOptionsSelected">
                {{ selectedItemsLabel }}
            </div>
            <div *ngIf="showSearch" id="search-box-item">
                <search-box
                    [placeholderKey]="placeholderKey"
                    autocomplete="off"
                    (searchTextChange)="onSearchTextChange($event)">
                </search-box>
            </div>
            <li *ngFor="let option of filteredOptions"
                class="click-target"
                [class.item-row-selected]="selectedItems[option.value]"
                [class.item-row-disabled]="option.disabled"
                (click)="!option.disabled && toggleSelected(option)"
                [attr.test-id]="'select-item-checkbox-' + option.value">
                <div class="item-container">
                    <i id="item-icon"
                       [class]="selectedItems[option.value] ? 'fas fa-check-square' : 'far fa-square'"></i>
                    {{ option.key | localize }}
                </div>
            </li>
            <div *ngIf="savable" id="ds-dropdown-footer-row" class="dropdown-footer">
                <action-buttons [inline]="true"
                                [saveText]="'APPLY' | localize"
                                sizes="small"
                                (cancel)="cancel()"
                                (save)="saveOptions()">
                </action-buttons>
            </div>
        </ds-dropdown>
    `,
    styles: [`
        @import "/src/styles/colors";

        li {
            line-height: 2rem;
            overflow: hidden;
            white-space: nowrap;
        }

        #item-icon {
            color: $gray-6;
            font-size: 16px;

            &.fa-check-square {
                color: $ds-blue-6;
            }
        }

        .item-container {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        #ds-dropdown-trigger.dropdown-value:not(.value-selected) {
            color: $gray-6;
            font-weight: 400;
        }

        #ds-dropdown-trigger {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        #search-box-item {
            white-space: nowrap;
            line-height: 2rem;
            overflow: hidden;
            margin: 0rem 1rem;
        }

        .item-row-selected {
            background-color: $gray-4;
        }

        .item-row-disabled {
            color: $gray-6;
        }

        #ds-dropdown-footer-row {
            justify-content: flex-end;
        }

        .dropdown-footer {
            display: flex;
            justify-content: space-between;
        }
    `],
})
export class DsMultiSelectComponent<V extends DsSelectValue> implements OnInit, OnChanges {
    @ViewChild(DsDropdownComponent) dsDropdown: DsDropdownComponent;
    @Input() control: AbstractControl;
    @Input() placeholderKey: string = "SELECT";
    @Input() selectedOption: V[];
    @Input() options: DsSelectOption<V>[];
    @Input() filteredOptions: DsSelectOption<V>[];
    @Input() showSearch: boolean;
    @Input() shortLabel: boolean;
    @Input() dropdownClass: string;
    @Input() savable: boolean;
    @Output() selectedOptionsChange = new EventEmitter<V[]>();

    optionsByValue: Record<string, DsSelectOption<V>> = {};
    selectedItems: Record<string, DsSelectOption<V>> = {};
    savedItems: Record<string, DsSelectOption<V>> = {};
    anyOptionsSelected: boolean;
    selectedItemsLabel: string;
    searchText = "";

    ngOnInit() {
        if (this.control) {
            this.control.valueChanges
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.refreshSelectedItems();
                });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && this.options) {
            this.filteredOptions = this.options;
            this.optionsByValue = keyBy(this.options, "value");
            this.refreshSelectedItems();
            this.savedItems = cloneDeep(this.selectedItems);
            this.anyOptionsSelected = this.anySelected;
            this.selectedItemsLabel = this.updatedSelectedItemsLabel;
        }

        if (changes.selectedOption && this.selectedOption) {
            this.selectedItems = {};
            this.selectedOption.forEach(option => {
                const key = String(option);
                if (this.optionsByValue[key]) {
                    this.selectedItems[key] = this.optionsByValue[key];
                }
            });
            this.control?.setValue(Object.keys(this.selectedItems));
            this.savedItems = cloneDeep(this.selectedItems);
            this.anyOptionsSelected = this.anySelected;
            this.selectedItemsLabel = this.updatedSelectedItemsLabel;
        }
    }

    get anySelected(): boolean {
        return !!Object.keys(this.selectedItems).length;
    }

    get updatedSelectedItemsLabel(): string {
        if (!this.anySelected) {
            return localization.getString(this.placeholderKey);
        } else if (this.shortLabel && Object.keys(this.selectedItems).length > 1) {
            return localization.getString("SELECTED") + " (" + Object.keys(this.selectedItems).length + ")";
        }
        return Object.values(this.selectedItems).map(v => localization.getString(v.key)).sort().join(", ");
    }

    toggleSelected(option: DsSelectOption<V>): void {
        if (this.selectedItems[option.value.toString()]) {
            delete this.selectedItems[option.value.toString()];
        } else {
            this.selectedItems[option.value.toString()] = option;
        }

        this.control?.setValue(Object.keys(this.selectedItems).sort());
        if (!this.savable) {
            this.selectedItemsLabel = this.updatedSelectedItemsLabel;
            this.anyOptionsSelected = this.anySelected;
            this.selectedOptionsChange.emit(Object.values(this.selectedItems).map(option => option.value));
        }
    }

    private refreshSelectedItems() {
        this.selectedItems = {};
        if (this.control) {
            this.control.value.forEach(v => {
                if (this.optionsByValue[v]) {
                    this.selectedItems[v] = this.optionsByValue[v];
                }
            });
        } else if (this.selectedOption) {
            this.selectedOption.forEach(option => {
                const key = String(option);
                if (this.optionsByValue[key]) {
                    this.selectedItems[key] = this.optionsByValue[key];
                }
            });
        }
    }


    onSearchTextChange(searchText: string): void {
        this.searchText = searchText;
        const lowerSearchText = this.searchText.toLocaleLowerCase();
        this.filteredOptions = this.options
            .filter(option => option.key.toLocaleLowerCase().includes(lowerSearchText));
    }

    saveOptions() {
        this.selectedItemsLabel = this.updatedSelectedItemsLabel;
        this.anyOptionsSelected = this.anySelected;
        this.selectedOptionsChange.emit(Object.values(this.selectedItems).map(option => option.value));
        this.dsDropdown.hideDropdown();
    }

    cancel() {
        this.selectedItems = cloneDeep(this.savedItems);
        this.dsDropdown.hideDropdown();
    }
}
