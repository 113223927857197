import { Component } from "@angular/core";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { FeaturesService } from "@app2/shared/services/features.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "navigation-top-bar-settings",
    template: `
        <nav *ngIf="hasNewTabNavEnabled"
             class="top-bar new-tab-nav" role="navigation">
            <section class="top-bar-section nav-section-main">
                <h1 class="nav-top-item-main">{{ "SETTINGS" | localize }}</h1>
                <ul class="left">
                    <li [class.active]="navSectionService.isUsersListActive$ | async"
                        *dsPermissionsRequired="'USER_VIEW'">
                        <a dsRouterLink="/settings/users">{{"SETTINGS_USERS" | localize}}</a></li>
                    <li [class.active]="navSectionService.isInputTokensListActive$ | async"
                        *dsPermissionsRequired="'AUTH_TOKEN_VIEW'">
                        <a dsRouterLink="/settings/input-tokens">{{"SETTINGS_SOURCES" | localize}}</a></li>
                    <li [class.active]="navSectionService.isActionIntegrationsActive$ | async"
                        *featureGate="'automatedIntervention'">
                        <!-- TODO: remove featureGate directive and require INTEGRATION_EDIT permissions when live -->
                        <a dsRouterLink="/settings/action-integrations">{{'SETTINGS_ACTION_INTEGRATIONS' | localize}}</a></li>
                    <li [class.active]="navSectionService.isIntegrationsActive$ | async"
                        *dsPermissionsRequired="'INTEGRATION_VIEW'">
                        <a dsRouterLink="/settings/integrations">{{'SETTINGS_INTEGRATIONS' | localize}}</a></li>
                    <li [class.active]="navSectionService.isDataRestrictionsActive$ | async"
                        *dsPermissionsRequired="'DATA_RESTRICTION_VIEW'">
                        <a dsRouterLink="/settings/data-restrictions">{{'DATA_RESTRICTIONS' | localize}}</a></li>
                    <li [class.active]="navSectionService.isUserProfileActive$ | async">
                        <a dsRouterLink="/settings/profile">{{"SETTINGS_PROFILE" | localize}}</a></li>
                    <li [class.active]="navSectionService.isOrgSettingsActive$ | async"
                        *dsPermissionsRequired="'GLOBAL_SETTINGS_VIEW'">
                        <a dsRouterLink="/settings/org-settings">{{"ORG_SETTINGS" | localize}}</a></li>
                    <li [class.active]="navSectionService.isRolesListActive$ | async"
                        *dsPermissionsRequired="'USER_VIEW'">
                        <a dsRouterLink="/settings/roles">{{"ROLES" | localize}}</a></li>
                    <ng-container *featureGate="'escalationPage'">
                        <li [class.active]="navSectionService.isEscalationPageActive$ | async"
                            *dsPermissionsRequired="'ESCALATION_VIEW'">
                            <a dsRouterLink="/settings/escalation">{{"ESCALATION" | localize}}</a></li>
                    </ng-container>
                    <li [class.active]="navSectionService.isGlobalTagSearchActive$ | async"
                        *dsPermissionsRequired="'TAG_VIEW'">
                        <a dsRouterLink="/settings/tags">{{"SETTINGS_TAGS" | localize}}</a></li>
                    <ng-container *featureGate="'jobDescriptors'">
                        <li [class.active]="navSectionService.isJobDescriptorsPageActive$ | async"
                            *dsPermissionsRequired="'TRAC_USER'">
                            <a dsRouterLink="/settings/job-descriptors">{{"JOB_DESCRIPTORS" | localize}}</a></li>
                    </ng-container>
                </ul>
            </section>
        </nav>
        <nav *ngIf="!hasNewTabNavEnabled"
             class="top-bar" role="navigation">
            <section class="top-bar-section nav-section-settings">
                <ul class="left settings-margin-top">
                    <li [class.active]="navSectionService.isUsersListActive$ | async"
                        *dsPermissionsRequired="'USER_VIEW'">
                        <a dsRouterLink="/settings/users">{{"SETTINGS_USERS" | localize}}</a></li>
                    <li [class.active]="navSectionService.isInputTokensListActive$ | async"
                        *dsPermissionsRequired="'AUTH_TOKEN_VIEW'">
                        <a dsRouterLink="/settings/input-tokens">{{"SETTINGS_SOURCES" | localize}}</a></li>
                    <li [class.active]="navSectionService.isActionIntegrationsActive$ | async"
                        *featureGate="'automatedIntervention'">
                        <!-- TODO: remove featureGate directive and require INTEGRATION_EDIT permissions when live -->
                        <a dsRouterLink="/settings/action-integrations">{{'SETTINGS_ACTION_INTEGRATIONS' | localize}}</a></li>
                    <li [class.active]="navSectionService.isIntegrationsActive$ | async"
                        *dsPermissionsRequired="'INTEGRATION_VIEW'">
                        <a dsRouterLink="/settings/integrations">{{'SETTINGS_INTEGRATIONS' | localize}}</a></li>
                    <li [class.active]="navSectionService.isDataRestrictionsActive$ | async"
                        *dsPermissionsRequired="'DATA_RESTRICTION_VIEW'">
                        <a dsRouterLink="/settings/data-restrictions">{{'DATA_RESTRICTIONS' | localize}}</a></li>
                    <li [class.active]="navSectionService.isUserProfileActive$ | async">
                        <a dsRouterLink="/settings/profile">{{"SETTINGS_PROFILE" | localize}}</a></li>
                    <li [class.active]="navSectionService.isOrgSettingsActive$ | async"
                        *dsPermissionsRequired="'GLOBAL_SETTINGS_VIEW'">
                        <a dsRouterLink="/settings/org-settings">{{"ORG_SETTINGS" | localize}}</a></li>
                    <li [class.active]="navSectionService.isRolesListActive$ | async"
                        *dsPermissionsRequired="'USER_VIEW'">
                        <a dsRouterLink="/settings/roles">{{"ROLES" | localize}}</a></li>
                    <ng-container *featureGate="'escalationPage'">
                        <li [class.active]="navSectionService.isEscalationPageActive$ | async"
                            *dsPermissionsRequired="'ESCALATION_VIEW'">
                            <a dsRouterLink="/settings/escalation">{{"ESCALATION" | localize}}</a></li>
                    </ng-container>
                    <li [class.active]="navSectionService.isGlobalTagSearchActive$ | async"
                        *dsPermissionsRequired="'TAG_VIEW'">
                        <a dsRouterLink="/settings/tags">{{"SETTINGS_TAGS" | localize}}</a></li>
                    <ng-container *featureGate="'jobDescriptors'">
                        <li [class.active]="navSectionService.isJobDescriptorsPageActive$ | async"
                            *dsPermissionsRequired="'TRAC_USER'">
                            <a dsRouterLink="/settings/job-descriptors">{{"JOB_DESCRIPTORS" | localize}}</a></li>
                    </ng-container>
                </ul>
            </section>
        </nav>
    `,
})
export class NavigationTopBarSettingsComponent {
    hasNewTabNavEnabled: boolean;

    constructor(public navSectionService: ActiveNavSectionService,
                private featuresService: FeaturesService) {
        this.featuresService.getFeature$("newTabNav")
            .pipe(untilDestroyed(this))
            .subscribe(isEnabled => {
                this.hasNewTabNavEnabled = isEnabled;
                document.documentElement.style.setProperty("--nav-top-bar-height",
                    isEnabled ? "5.75rem" : "3.5rem");
            });
    }
}
