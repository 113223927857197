import { Component } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { UserService } from "@app2/account/user.service";
import { hasAnyFraudSku } from "@app2/fraud/utils";
import { hasAnyRiskSku } from "@app2/risk/utils";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { FeaturesService } from "@app2/shared/services/features.service";
import { StorageService } from "@app2/shared/services/storage.service";
import { ProductSku } from "@app2/type-defs/user/user-types";
import { StorageServiceKeys } from "@app2/util/storage-service-keys";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

interface ModuleState {
    url: string;
    queryParams?: object;
    requiredPerm?: string;
    requiredSku?: (orgSkus: ProductSku[]) => boolean;
}

// The requiredPerm is technically optional for the last item in each list, as if the module icon is showing up,
// at least the last option will be available.
const allModules: Record<string, Array<ModuleState>> = {
    events: [
        {
            url: "/search",
            requiredPerm: "EVENT_VIEW",
        },
        {
            url: "/search/saved",
            requiredPerm: "SAVED_SEARCH_VIEW",
        },
        {
            url: "/classifiers",
            requiredPerm: "CLASSIFIER_VIEW",
        },
    ],
    alerts: [
        {
            url: "/alerts/inbox",
            requiredPerm: "ALERT_INBOX_VIEW",
        },
        {
            url: "/triggers",
            requiredPerm: "TRIGGER_VIEW",
        },
        {
            url: "/threat",
            requiredPerm: "THREATMATCH_VIEW",
        },
    ],
    tickets: [
        {
            url: "/tickets/incident",
            requiredPerm: "INCIDENT_VIEW",
        },
    ],
    fraud: [
        {
            url: "/fraud/inbox",
            requiredPerm: "ALERT_INBOX_VIEW",
            requiredSku: hasAnyFraudSku,
        },
        {
            url: "/fraud/offering",
        },
    ],
    compliance: [
        {
            url: "/governance/tasks",
            requiredPerm: "TASK_VIEW",
        },
        {
            url: "/task-schedules",
            requiredPerm: "TASK_SCHEDULE_VIEW",
        },
        {
            url: "/governance/frameworks",
            requiredPerm: "POLICY_FRAMEWORK_VIEW",
        },
        {
            url: "/governance/policies",
        },
    ],
    risk: [
        {
            url: "/risk/risks/register",
            requiredSku: hasAnyRiskSku,
        },
        {
            url: "/risk/risks/library",
        },
    ],
    assets: [
        {
            url: "/assets",
            requiredPerm: "ASSET_VIEW",
        },
    ],
    reports: [
        {
            url: "/reports",
            requiredPerm: "REPORT_VIEW",
        },
        {
            url: "/reports/charts/list",
            requiredPerm: "CHART_VIEW",
        },
    ],
    settings: [
        {
            url: "/settings/users",
            requiredPerm: "USER_VIEW",
        },
        {
            url: "/settings/profile",
        },
    ],
};

@UntilDestroy()
@Component({
    selector: "old-nav-left-bar",
    template: `
        <nav>
            <ul id="branding" class="left-navbar">
                <li><a [dsRouterLink]="revealDashboardsEnabled ? '/dashboards/default' : '/dashboard'">
                    <div class="branding-icon">
                        <svg-icon src="assets/icons/branding.svg"></svg-icon>
                    </div>
                </a></li>
            </ul>
            <div class="scrollable-container">
                <ul id="menu" class="left-navbar">
                    <li class="section-main" [class.active]="navSectionService.isEventsActive$ | async"
                        *dsPermissionsRequired="'any: EVENT_VIEW, SAVED_SEARCH_VIEW, CLASSIFIER_VIEW'"
                        test-id="main-events-icon">
                        <a [dsRouterLink]="defaultPages['events']?.url">
                            <div class="left-navbar-icon">
                                <svg-icon src="assets/icons/events.svg"></svg-icon>
                                <span>{{ 'EVENTS' | localize }}</span>
                            </div>
                        </a></li>
                    <li class="section-main"
                        [class.active]="(navSectionService.isAlertsActive$ | async)"
                        *dsPermissionsRequired="'any: ALERT_INBOX_VIEW, TRIGGER_VIEW, THREATMATCH_VIEW'"
                        test-id="main-alerts-icon">
                        <a [dsRouterLink]="defaultPages['alerts']?.url">
                            <div class="left-navbar-icon">
                                <svg-icon src="assets/icons/alerts.svg"></svg-icon>
                                <span>{{ 'ALERTS' | localize }}</span>
                            </div>
                        </a></li>
                    <li class="section-main" [class.active]="navSectionService.isTicketsActive$ | async"
                        *dsPermissionsRequired="'any: INCIDENT_VIEW, TASK_VIEW, TASK_SCHEDULE_VIEW'"
                        test-id="main-tickets-icon">
                        <a *ngIf="defaultPages['tickets']?.url"
                           [dsRouterLink]="defaultPages['tickets']?.url">
                            <div class="left-navbar-icon">
                                <svg-icon id="tickets-left-icon" src="assets/icons/tickets.svg"></svg-icon>
                                <span>{{ 'TICKETS' | localize }}</span>
                            </div>
                        </a>
                    </li>
                    <li class="section-main" [class.active]="navSectionService.isFraudActive$ | async"
                        *dsPermissionsRequired="'ALERT_INBOX_VIEW'"
                        test-id="main-fraud-icon">
                        <a [dsRouterLink]="defaultPages['fraud']?.url">
                            <div class="left-navbar-icon">
                                <i class="fa fa-user-secret font-icon-nav"></i>
                                <span>{{ 'FRAUD' | localize }}</span>
                            </div>
                        </a>
                    </li>
                    <li class="section-main" [class.active]="navSectionService.isComplianceActive$ | async"
                        *dsPermissionsRequired="'any: CAT_POLICY_VIEW, CUSTOM_POLICY_VIEW'"
                        test-id="main-compliance-icon">
                        <a [dsRouterLink]="defaultPages['compliance'].url">
                            <div class="left-navbar-icon">
                                <svg-icon src="assets/icons/compliance.svg"></svg-icon>
                                <span>{{ 'GOVERNANCE' | localize }}</span>
                            </div>
                        </a></li>
                    <li class="section-main" [class.active]="navSectionService.isRiskActive$ | async"
                        *dsPermissionsRequired="'any: RISK_VIEW'">
                        <a [dsRouterLink]="defaultPages['risk']?.url">
                            <div class="left-navbar-icon">
                                <i class="fa-kit fa-tachometer-solid font-icon-nav"></i>
                                <span>{{ 'RISK' | localize }}</span>
                            </div>
                        </a></li>
                    <li class="section-main" [class.active]="navSectionService.isAssetsActive$ | async"
                        *dsPermissionsRequired="'any: ASSET_VIEW'"
                        test-id="main-assets-icon">
                        <a [dsRouterLink]="defaultPages['assets']?.url">
                            <div class="left-navbar-icon">
                                <i class="ion-cube font-icon-nav"></i>
                                <span>{{ 'ASSETS' | localize }}</span>
                            </div>
                        </a></li>
                    <li class="section-main" [class.active]="navSectionService.isReportsActive$ | async"
                        *dsPermissionsRequired="'any: REPORT_VIEW, CHART_VIEW'"
                        test-id="main-reports-icon">
                        <a [dsRouterLink]="defaultPages['reports']?.url">
                            <div class="left-navbar-icon">
                                <i class="ion-stats-bars font-icon-nav"></i>
                                <span>{{ 'REPORTS' | localize }}</span>
                            </div>
                        </a></li>

                    <li class="settings-spacer"></li>

                    <li class="section-settings" [class.active]="navSectionService.isSettingsActive$ | async"
                        test-id="main-settings-icon">
                        <a [dsRouterLink]="defaultPages['settings']?.url">
                            <div class="left-navbar-icon">
                                <svg-icon src="assets/icons/settings.svg"></svg-icon>
                                <span>{{ 'SETTINGS' | localize }}</span>
                            </div>
                        </a>
                </ul>
            </div>
        </nav>
    `,
})
export class OldNavLeftBarComponent {

    allModules: Record<string, Array<ModuleState>> = allModules;
    defaultPages: Record<string, ModuleState>;
    currentPerms: string[];

    revealDashboardsEnabled: boolean;

    constructor(public navSectionService: ActiveNavSectionService,
                userService: UserService,
                private orgsService: OrgsService,
                private storageService: StorageService,
                private featuresService: FeaturesService) {
        userService.getCurrentPermissions$()
            .pipe(untilDestroyed(this))
            .subscribe(permissions => {
                this.currentPerms = permissions;
                this.updateDefaultPages();
            });

        featuresService.getFeature$("defaultRevealDashboards")
            .pipe(untilDestroyed(this))
            .subscribe(enabled => {
                if (enabled) {
                    this.revealDashboardsEnabled = !this.storageService.keyExists(StorageServiceKeys.useNewDashboards)
                        ? true : this.storageService.get(StorageServiceKeys.useNewDashboards);
                } else {
                    this.revealDashboardsEnabled = !!this.storageService.get(StorageServiceKeys.useNewDashboards);
                }
                this.storageService.set(StorageServiceKeys.useNewDashboards, this.revealDashboardsEnabled);
            });
    }

    private updateDefaultPages() {
        this.defaultPages = {};

        // Assign to each element of `defaultPages` the first router state the user has access to.
        for (let [module, states] of Object.entries(this.allModules)) {
            for (let state of states) {
                const hasPerms = !state.requiredPerm || this.currentPerms.indexOf(state.requiredPerm) !== -1;

                const orgSkus = this.orgsService.getCurrentOrg()?.productSkus || [];
                const hasSku = !state.requiredSku || state.requiredSku(orgSkus);
                if (hasPerms && hasSku) {
                    this.defaultPages[module] = state;
                    break;
                }
            }
        }
    }
}
