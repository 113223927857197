import { Component } from "@angular/core";
import { toQueryString, toStateParams } from "@app2/search/query";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { RecentSearchesService } from "@app2/shared/services/recent-searches.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { FeaturesService } from "@app2/shared/services/features.service";

@UntilDestroy()
@Component({
    selector: "navigation-top-bar-events",
    template: `
        <nav *ngIf="hasNewTabNavEnabled"
             class="top-bar new-tab-nav" role="navigation">
            <section class="top-bar-section nav-section-main">
                <h1 class="nav-top-item-main">{{ "EVENTS" | localize }}</h1>
                <ul class="left">
                    <li *dsPermissionsRequired="'EVENT_VIEW'"
                        [class.active]="navSectionService.isEventsListActive$ | async">
                        <a dsRouterLink="/search">{{ 'OVERVIEW' | localize }}</a>
                    </li>
                    <li [class.active]="navSectionService.isSavedQueriesActive$ | async"
                        *dsPermissionsRequired="'SAVED_SEARCH_VIEW'">
                        <a dsRouterLink="/search/saved">{{ "SAVED_QUERIES" | localize }}</a>
                    </li>
                    <li [class.active]="navSectionService.isClassifiersActive$ | async"
                        *dsPermissionsRequired="'CLASSIFIER_VIEW'">
                        <a dsRouterLink="/classifiers">{{ "BASE_CLASSIFIERS" | localize }}</a>
                    </li>
                    <ng-container *featureGate="'classifierLibrary'">
                        <li [class.active]="navSectionService.isLibraryClassifiersActive$ | async"
                            *dsPermissionsRequired="'TRAC_ENGINEER'">
                            <a dsRouterLink="/classifiers/library">{{ "LIBRARY_CLASSIFIERS" | localize }}</a>
                        </li>
                    </ng-container>
                    <li [class.active]="navSectionService.isCrossOrgSearchActive$ | async"
                        *dsPermissionsRequired="'any: TRAC_ADMIN, TRAC_ENGINEER'">
                        <a dsRouterLink="/search/cross-org-search">{{ "CROSS_ORG_SEARCH" | localize }}</a>
                    </li>
                </ul>
            </section>
        </nav>
        <nav *ngIf="!hasNewTabNavEnabled"
             class="top-bar" role="navigation">
            <section class="top-bar-section nav-section-main">
                <ul class="left">
                    <li class="nav-top-item-main"
                        *dsPermissionsRequired="'EVENT_VIEW'">
                        <pr-dropdown test-id="recent-searches-dropdown"
                                     [showOnHover]="true">
                            <div pr-dropdown-trigger>
                                <span dsRouterLink="/search" class="dropdown-value nav-top-item-main-text">
                                    {{ 'EVENTS' | localize }}
                                </span>
                            </div>
                            <ul pr-dropdown-body class="overflow-auto no-display-inline navbar-dropdown">
                                <li class="dropdown-fixed-text dropdown-item-full-width"
                                    *ngIf="recentSearches.length > 0"
                                    (click)="$event.stopPropagation()">
                                    {{ 'RECENT_SEARCHES' | localize }}
                                </li>
                                <li class="dropdown-fixed-text dropdown-item-full-width"
                                    *ngIf="recentSearches.length === 0">
                                    {{ 'NO_RECENT_SEARCHES' | localize }}
                                </li>
                                <li class="dropdown-item-full-width"
                                    *ngFor="let recentSearch of recentSearches">
                                    <a dsRouterLink="/search"
                                       [queryParams]="recentSearch.stateParams">
                                        {{ recentSearch.label }}</a>
                                </li>
                                <li class="divider" *ngIf="recentSearches.length > 0"></li>
                                <li class="dropdown-item-full-width"
                                    *ngIf="recentSearches.length > 0">
                                    <a (click)="clearRecentSearches()">
                                        {{ 'CLEAR_RECENT_SEARCHES' | localize }}</a>
                                </li>
                            </ul>
                        </pr-dropdown>
                    </li>
                    <li [class.active]="navSectionService.isSavedQueriesActive$ | async"
                        *dsPermissionsRequired="'SAVED_SEARCH_VIEW'">
                        <a dsRouterLink="/search/saved">{{ "SAVED_QUERIES" | localize }}</a></li>
                    <li [class.active]="navSectionService.isClassifiersActive$ | async"
                        *dsPermissionsRequired="'CLASSIFIER_VIEW'">
                        <a dsRouterLink="/classifiers">{{ "BASE_CLASSIFIERS" | localize }}</a></li>
                    <ng-container *featureGate="'classifierLibrary'">
                        <li [class.active]="navSectionService.isLibraryClassifiersActive$ | async"
                            *dsPermissionsRequired="'TRAC_ENGINEER'">
                            <a dsRouterLink="/classifiers/library">{{ "LIBRARY_CLASSIFIERS" | localize }}</a></li>
                    </ng-container>
                    <li [class.active]="navSectionService.isCrossOrgSearchActive$ | async"
                        *dsPermissionsRequired="'any: TRAC_ADMIN, TRAC_ENGINEER'">
                        <a dsRouterLink="/search/cross-org-search">{{ "CROSS_ORG_SEARCH" | localize }}</a>
                    </li>
                </ul>
            </section>
        </nav>
    `,
    styles: [`
        @import "/src/styles/colors";

        pr-dropdown div {
            height: 56px;
            padding-top: 4px;

            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 4px;
                width: 32px;
                background-color: $ds-blue-6;
            }
        }
    `],
})
export class NavigationTopBarEventsComponent {
    hasNewTabNavEnabled: boolean;
    recentSearches: Array<{ label: string, stateParams: object }>;

    constructor(public navSectionService: ActiveNavSectionService,
                private recentSearchesService: RecentSearchesService,
                private featuresService: FeaturesService) {
        recentSearchesService.getRecentItems$()
            .pipe(untilDestroyed(this))
            .subscribe(searches => {
                this.recentSearches = searches.map(search => {
                    const state = search.intervalType === "CUSTOM"
                        ? {
                            query: search.query,
                            filters: search.filters,
                            intervalType: search.intervalType,
                            begin: search.rangeBegin,
                            end: search.rangeEnd,
                        }
                        : {
                            query: search.query,
                            filters: search.filters,
                            intervalType: search.intervalType,
                        };
                    return {
                        label: toQueryString(search),
                        stateParams: toStateParams(state),
                    };
                });
            });

        this.featuresService.getFeature$("newTabNav")
            .pipe(untilDestroyed(this))
            .subscribe(isEnabled => {
                this.hasNewTabNavEnabled = isEnabled;
                document.documentElement.style.setProperty("--nav-top-bar-height",
                    isEnabled ? "5.75rem" : "3.5rem");
            });
    }

    clearRecentSearches() {
        this.recentSearchesService.clearRecentItems();
    }
}
