import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {RestClient} from "@app2/clients/rest-client";
import {EnvironmentService} from "@app2/shared/services/environment.service";
import {OrgsService} from "@app2/account/orgs.service";
import {
    MatchedThreat,
    RatedHostsWrapper,
    RatedSignaturesWrapper,
    RatedThreatHost,
    RatedThreatSignature,
    ThreatClientTimeout,
    ThreatGroup, ThreatGroupResponse,
    ThreatMatchConfig,
    ThreatSignature,
    ThreatSourceIndicators,
    ThreatSourceMatches,
    ThreatSourceSearchRequest,
    ThreatSourceSubscriptionSettings,
    ThreatUpload,
} from "@app2/type-defs/threat/threat-types";
import { Moment } from "@app2/util/legacy-moment";
import {Domain} from "@app2/alert/constants";
import {ProductSku} from "@app2/type-defs/user/user-types";
import * as _ from "underscore";

export interface ThreatSource {
    id: uuid;
    orgId: uuid | null;
    custom: boolean | null;
    name: string;
    description: string;
    url: string;
    isSubscribed: boolean;
    indicatorCount: number;
    matchCount: number;
    uniqueMatchCount: number;
    tags: string[];
    lastUpdated: date;
    createdOn: date;
    enabled: boolean; //once threat service is released, remove this default
    notifyOnly: boolean; // whether the pseudo-trigger that backs the threat should skip alert inbox
    domain: Domain;
    isWatchList?: boolean;
    requiredSku?: (orgSkus: ProductSku[]) => boolean;
}

export interface ThreatSourceWrapper {
    sources: ThreatSource[];
}

@Injectable({
    providedIn: "root",
})
export class ThreatClientService {
    private threatClient: RestClient;
    private threatClientNoOrg: RestClient;
    private hostPromise: Promise<RatedThreatHost[]> | undefined;
    private hostBatch: string[];
    private signaturePromise: Promise<RatedThreatSignature[]> | undefined;
    private signatureBatch: ThreatSignature[];

    constructor(httpClient: HttpClient,
                envService: EnvironmentService,
                orgsService: OrgsService) {
        const baseUrl = envService.getConfig().threatService;

        this.threatClient = new RestClient(baseUrl, httpClient, orgsService);
        this.threatClientNoOrg = new RestClient(baseUrl, httpClient);
        this.hostPromise = undefined;
        this.hostBatch = [];
        this.signaturePromise = undefined;
        this.signatureBatch = [];
    }

    public addGroupToSource(sourceId: uuid, threatGroup: ThreatGroup): Promise<void> {
        return this.threatClient.doPost(`/source/${sourceId}/group`, threatGroup);
    }
    public addGroupToCommonSource(sourceId: uuid, threatGroup: ThreatGroup): Promise<void> {
        return this.threatClientNoOrg.doPost(`/org/all/source/${sourceId}/group`, threatGroup);
    }
    public createSource(threatSource: Partial<ThreatSource>): Promise<ThreatSource> {
        return this.threatClient.doPost("/source", threatSource);
    }

    public createCommonSource(threatSource: Partial<ThreatSource>): Promise<ThreatSource> {
        return this.threatClientNoOrg.doPost("/org/all/source", threatSource);
    }

    public deleteCommonSource(id: uuid): Promise<void> {
        return this.threatClientNoOrg.doDelete<void>(`/org/all/source/${id}`);
    }

    public getUploadUrl(contentType: string): Promise<ThreatUpload> {
        return this.threatClient.doPost("/source/uploadUrl", undefined, {contentType});
    }

    public getCommonUploadUrl(contentType: string): Promise<ThreatUpload> {
        return this.threatClientNoOrg.doPost("/org/all/source/uploadUrl", undefined, {contentType});
    }

    public undeleteCommonSource(id: uuid): Promise<void> {
        return this.threatClientNoOrg.doPost<void>(`/org/all/source/${id}/undelete`);
    }

    public deleteSource(id: uuid): Promise<void> {
        return this.threatClient.doDelete<void>(`/source/${id}`);
    }

    public toggleThreatSourceIndicator(sourceId: uuid, indicatorId: number, indicatorType: string, enabled: boolean) {
        return this.threatClient.doPost(`/source/${sourceId}/indicator/${indicatorId}`,
            {},
            {indicatorType, enabled});
    }

    public deleteThreatSourceIndicator(sourceId: uuid, indicatorId: number, indicatorType: string) {
        return this.threatClient.doDelete(`/source/${sourceId}/indicator/${indicatorId}`,
            {indicatorType});
    }

    public undeleteSource(id: uuid): Promise<void> {
        return this.threatClient.doPost<void>(`/source/${id}/undelete`);
    }

    public getSources(): Promise<ThreatSource[]> {
        return this.threatClient.doGet("/sources", {includeDeleted: true}).then((wrapper: ThreatSourceWrapper) => wrapper.sources);
    }

    public getConfig(): Promise<ThreatMatchConfig> {
        return this.threatClient.doGet("/threatmatch/config");
    }

    public unsubscribeFromThreat(id: uuid): Promise<void> {
        return this.threatClient.doPost("/source/unsubscribe/" + id);
    }

    public subscribeToThreat(id: uuid, settings?: ThreatSourceSubscriptionSettings): Promise<void> {
        return this.threatClient.doPost("/source/subscribe/" + id, settings);
    }

    public getMatchesForSource(id: uuid): Promise<ThreatSourceMatches> {
        return this.threatClient.doGet("/source/" + id);
    }

    public getThreatSourceIndicators(search: ThreatSourceSearchRequest): Promise<ThreatSourceIndicators> {
        return this.threatClient.doPost(`/source/${search.threatSourceId}/indicators`, search);
    }

    public getThreatGroupInfoForThreatMatches(threatMatchIds: number[]): Promise<ThreatGroupResponse> {
        return this.threatClient.doPost("/matches/threatGroupInfo", threatMatchIds);
    }

    public getMatchedThreatsById(threatMatchIds: number[]): Promise<MatchedThreat[]> {
        return this.threatClient.doPost("/matches", threatMatchIds);
    }

    public rateHost(host: string, rating: string) {
        const params = {
            host: host,
            rating: rating,
        };
        return this.threatClient.doPost("/host/rate", null, params);
    }

    public rateSignature(signatureHex: string, signatureType: string, rating: string) {
        const params = {
            signatureHex: signatureHex,
            signatureType: signatureType,
            rating: rating,
        };
        return this.threatClient.doPost("/signature/rate", null, params);
    }

    public ignoreHost(host: string, ignoreUntil?: Moment) {
        const params = {
            host: host,
            ignoreUntil: ignoreUntil ? ignoreUntil.toISOString() : undefined,
        };
        return this.threatClient.doPost("/host/ignore", null, params);
    }

    public ignoreSignature(signatureHex: string, signatureType: string, ignoreUntil?: Moment) {
        const params = {
            signatureHex: signatureHex,
            signatureType: signatureType,
            ignoreUntil: ignoreUntil ? ignoreUntil.toISOString() : undefined,
        };
        return this.threatClient.doPost("/signature/ignore", null, params);
    }

    public unrateHost(host: string) {
        const params = {
            host: host,
        };
        return this.threatClient.doDelete("/host/rate", params);
    }

    public unrateSignature(signature: ThreatSignature) {
        const params = {
            signatureHex: signature.hex,
            signatureType: signature.type,
        };
        return this.threatClient.doDelete("/signature/rate", params);
    }

    public updateConfig(config: ThreatMatchConfig): Promise<ThreatMatchConfig> {
        return this.threatClient.doPut("/threatmatch/config", config);
    }

    public getRatedHost(host: string): Promise<RatedThreatHost> {
        this.hostBatch.push(host);
        if (this.hostPromise === undefined) {
            const self = this;
            const deferred = new Promise<RatedThreatHost[]>(function (resolve) {
                setTimeout(() => {
                    const resource = "/hosts/get-ratings";
                    const hosts = _.chain([...self.hostBatch]).unique().filter(s => !_.isEmpty(s)).value();
                    self.hostBatch = [];
                    self.hostPromise = undefined;
                    self.threatClient.doPost(resource, hosts)
                        .then((wrapper: RatedHostsWrapper) => {
                            resolve(wrapper.ratedHosts);
                        })
                        .catch(() => {
                            return {ratedHosts: []};
                        });
                }, ThreatClientTimeout);
            });
            this.hostPromise = deferred;
        }

        return this.hostPromise!
            .then((threatHosts: RatedThreatHost[]) => {
                return  _.find(threatHosts, h => h.host === host)
                    || { host: host, rating: null, ratingTime: null };
            });
    }

    public getRatedSignature(signature: ThreatSignature) {
        this.signatureBatch.push(signature);

        if (this.signaturePromise === undefined) {
            const self = this;
            const deferred = new Promise<RatedThreatSignature[]>(function (resolve) {
                setTimeout(() => {
                    const resource = "/signatures/get-ratings";
                    const signatures = _.chain([...self.signatureBatch]).unique().filter(s => !_.isEmpty(s)).value();
                    self.signatureBatch = [];
                    self.signaturePromise = undefined;
                    self.threatClient.doPost(resource, signatures)
                        .then((wrapper: RatedSignaturesWrapper) => {
                            resolve(wrapper.ratedSignatures);
                        })
                        .catch(() => {
                            return {ratedSignatures: []};
                        });
                }, ThreatClientTimeout);
            });
            this.signaturePromise = deferred;
        }

        return this.signaturePromise!
            .then((threatSignatures: RatedThreatSignature[]) => {
                return  _.find(threatSignatures, ts => this.signaturesEqual(ts.signature, signature))
                    || { signature: signature, rating: null, ratingTime: null };
            });
    }

    public signaturesEqual(a: ThreatSignature, b: ThreatSignature) {
        return a.hex.toLowerCase() === b.hex.toLowerCase() && (
            a.type === b.type || a.type === null || b.type === null
        );
    }
}
