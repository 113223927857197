import { Component, HostBinding, OnInit, ViewChild } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { UserService } from "@app2/account/user.service";
import { MetadataClientService } from "@app2/clients/metadata-client.service";
import { InfoPanelComponent } from "@app2/navigation/info-panel/info-panel.component";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { FeaturesService } from "@app2/shared/services/features.service";
import { InfoBannerService } from "@app2/shared/services/info-banner.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { RouterService } from "@app2/shared/services/router.service";
import { Keys, StorageService } from "@app2/shared/services/storage.service";
import { InfoPanelOrgMetadata } from "@app2/type-defs/metadata/metadata-types";
import { getMessageForError } from "@app2/util/errors/handleable-errors";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";

@UntilDestroy()
@Component({
    selector: "navigation",
    template: `
        <fa-pro-icons *ngIf="!isNewTab"></fa-pro-icons>
        <ng-container *ngIf="newNavigationView">
            @if (infoPanelEnabled) {
                <navigation-left-bar *ngIf="authenticated && !isNewTab"
                                     [leftBarVisible]="leftBarVisible"
                                     (toggleLeftBar)="toggleLeftBar()"/>
                <navigation-header-bar *dsLoading="loadingOrgMetadata"
                                       [authenticated]="authenticated"
                                       [currentThreatLevel]="infoPanelOrgMetadata?.threatLevel"
                                       (toggleInfoPanel)="toggleInfoPanel()"></navigation-header-bar>
                <info-panel #infoPanel
                            *ngIf="infoPanelOrgMetadata"
                            [orgMetadata]="infoPanelOrgMetadata"
                            (updateOrgMetadata)="infoPanelOrgMetadata = $event">
                </info-panel>
            } @else {
                <navigation-left-bar *ngIf="authenticated && !isNewTab"
                                     [leftBarVisible]="leftBarVisible"
                                     (toggleLeftBar)="toggleLeftBar()"/>
                <navigation-header-bar [authenticated]="authenticated"></navigation-header-bar>
            }
        </ng-container>
        <ng-container *ngIf="!newNavigationView">
            <div *ngIf="!authenticated">
                <navigation-left-bar-no-auth></navigation-left-bar-no-auth>
            </div>
            <div *ngIf="authenticated && !isNewTab">
                <old-nav-left-bar></old-nav-left-bar>
                <old-nav-header-bar></old-nav-header-bar>
            </div>
        </ng-container>
        <navigation-top-bar-home class="navigation-top-bar"
                                 *ngIf="navSectionService.isHomeActive$ | async">
        </navigation-top-bar-home>
        <navigation-top-bar-dashboard class="navigation-top-bar"
                                      *ngIf="navSectionService.isDashboardsActive$ | async">
        </navigation-top-bar-dashboard>
        <navigation-top-bar-events class="navigation-top-bar"
                                   *ngIf="navSectionService.isEventsActive$ | async">
        </navigation-top-bar-events>
        <navigation-top-bar-alerts class="navigation-top-bar"
                                   *ngIf="navSectionService.isAlertsActive$ | async">
        </navigation-top-bar-alerts>
        <navigation-top-bar-tickets class="navigation-top-bar"
                                    *ngIf="!isNewTab && navSectionService.isTicketsActive$ | async">
        </navigation-top-bar-tickets>
        <navigation-top-bar-fraud class="navigation-top-bar"
                                  *ngIf="navSectionService.isFraudActive$ | async">
        </navigation-top-bar-fraud>
        <navigation-top-bar-policy class="navigation-top-bar"
                                   *ngIf="navSectionService.isComplianceActive$ | async">
        </navigation-top-bar-policy>
        <navigation-top-bar-risk class="navigation-top-bar"
                                 *ngIf="navSectionService.isRiskActive$ | async">
        </navigation-top-bar-risk>
        <navigation-top-bar-assets class="navigation-top-bar"
                                   *ngIf="navSectionService.isAssetsActive$ | async">
        </navigation-top-bar-assets>
        <navigation-top-bar-reports class="navigation-top-bar"
                                    *ngIf="navSectionService.isReportsActive$ | async">
        </navigation-top-bar-reports>
        <navigation-top-bar-settings class="navigation-top-bar"
                                     *ngIf="navSectionService.isSettingsActive$ | async">
        </navigation-top-bar-settings>
    `,
})
export class NavigationComponent implements OnInit {
    authenticated: boolean;
    isNewTab: boolean;
    leftBarVisible: boolean;
    newNavigationView: boolean;
    infoPanelEnabled: boolean;
    infoPanelOrgMetadata: InfoPanelOrgMetadata;
    loadingOrgMetadata: boolean;

    @ViewChild("infoPanel")
    private infoPanel: InfoPanelComponent;

    @HostBinding("class.info-banner-visible") infoBannerVisible = false;

    constructor(private userService: UserService,
                private orgsService: OrgsService,
                private infoBannerService: InfoBannerService,
                public navSectionService: ActiveNavSectionService,
                private readonly featuresService: FeaturesService,
                private readonly storageService: StorageService,
                private readonly metadataService: MetadataClientService,
                private notificationsService: NotificationsService,
                private router: RouterService) {

    }

    ngOnInit() {
        combineLatest([this.userService.getCurrentUser$(), this.orgsService.getCurrentOrg$()])
            .pipe(untilDestroyed(this))
            .subscribe(([user, currentOrg]) => {
                this.authenticated = !!user && !!currentOrg;
                if (this.authenticated) {
                    const tempLeftBarValue = this.storageService.getShared(Keys.leftBarVisible);
                    this.leftBarVisible = tempLeftBarValue === undefined ? false : tempLeftBarValue;
                    this.adjustLeftBar();

                    combineLatest([this.userService.hasPermission$("ANY: TRAC_ADMIN,TRAC_ENGINEER,TRAC_USER"),
                        this.featuresService.getFeature$("infoPanel")])
                        .pipe(untilDestroyed(this))
                        .subscribe(([hasPermission, infoPanelEnabled]) => {
                            this.infoPanelEnabled = infoPanelEnabled;
                            if (infoPanelEnabled && hasPermission) {
                                this.loadingOrgMetadata = true;
                                this.metadataService.getInfoPanelOrgMetadata()
                                    .then(infoPanelOrgMetadata => {
                                        this.infoPanelOrgMetadata = infoPanelOrgMetadata;
                                    })
                                    .catch(error => {
                                        const note = getMessageForError(error.error,
                                            "ERROR_LOADING_ORG_METADATA");
                                        this.notificationsService.showError(note, error);
                                    })
                                    .finally(() => this.loadingOrgMetadata = false);
                            }
                        });
                }
            });

        this.infoBannerService.isBannerVisible$()
            .pipe(untilDestroyed(this))
            .subscribe(visible => this.infoBannerVisible = visible);

        this.router.getQueryParams$()
            .pipe(untilDestroyed(this))
            .subscribe((queryParams) => {
                this.isNewTab = queryParams.isNewTab!!;
            });

        this.featuresService.getFeature$("newNavigationView")
            .pipe(untilDestroyed(this))
            .subscribe(isEnabled => this.newNavigationView = isEnabled);
    }

    toggleLeftBar() {
        this.leftBarVisible = !this.leftBarVisible;
        this.storageService.setShared(Keys.leftBarVisible, this.leftBarVisible);
        this.adjustLeftBar();
    }

    toggleInfoPanel() {
        this.infoPanel.toggleVisibility();
    }

    private adjustLeftBar() {
        document.documentElement.style.setProperty("--nav-left-bar-width",
            this.leftBarVisible ? "15.625rem" : "5.5rem");
    }
}
