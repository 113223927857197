import { Injectable } from "@angular/core";
import { RouterService } from "@app2/shared/services/router.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

@UntilDestroy()
@Injectable({
    providedIn: null,
})
export class ActiveNavSectionService {
    url$: Observable<string>;

    constructor(router: RouterService) {
        this.url$ = router.getUrl$()
            .pipe(
                untilDestroyed(this),
                distinctUntilChanged(),
            );
    }

    /** Top-Level Page Groups */

    get isHomeActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/home")));
    }

    get isDashboardsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/dashboard")));
    }

    get isEventsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/search") ||
            url.startsWith("/classifiers")));
    }

    get isAlertsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/alerts") ||
            url.startsWith("/trigger") ||
            url.startsWith("/trigger-schedules") ||
            url.startsWith("/threat") ||
            url.startsWith("/watchlists")));
    }

    get isTicketsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url =>
            url.startsWith("/ticket-templates") ||
            url.startsWith("/tickets") ||
            url.startsWith("/universal-ticket-inbox")));
    }

    get isInboxTasksActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/universal-ticket-inbox/task")));
    }

    get isInboxIncidentsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/universal-ticket-inbox/incident")));
    }

    get isInboxDraftsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/universal-ticket-inbox/draft")));
    }

    get isFraudActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/fraud")));
    }

    get isComplianceActive$(): Observable<boolean> {
        return this.url$.pipe(map(url =>
            url.startsWith("/library-task-schedules") ||
            url.startsWith("/task-schedule") ||
            url.startsWith("/governance")));
    }

    get isRiskActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/risk")));
    }

    get isAssetsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/assets")));
    }

    get isReportsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/reports")));
    }

    get isSettingsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/settings")));
    }

    /** Dashboards Pages */

    get isOldMainDashboardActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/dashboard") &&
            !url.startsWith("/dashboards") &&
            !url.startsWith("/dashboard/template")));
    }

    get isOldDashboardActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/dashboard/template")));
    }

    get isDefaultDashboardActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/dashboards/default")));
    }

    get isDashboardsListActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/dashboards") &&
            !url.startsWith("/dashboards/library") &&
            !url.startsWith("/dashboards/default") &&
            !url.startsWith("/dashboards/workspace-tiles-page") &&
            !url.includes("/view")));
    }

    get isLibraryDashboardsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/dashboards/library")));
    }

    /** Events Pages */

    get isEventsListActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/search") &&
            !url.startsWith("/search/")));
    }

    get isSavedQueriesActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/search/saved")));
    }

    get isClassifiersActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/classifiers") &&
            !url.startsWith("/classifiers/library")));
    }

    get isLibraryClassifiersActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/classifiers/library")));
    }

    get isCrossOrgSearchActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/search/cross-org-search")));
    }

    /** Alert Pages */

    get isAlertInboxActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/alerts/inbox")));
    }

    get isTriggersActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/trigger") &&
            /^\/triggers\/\w{32}\/stats/.exec(url) === null &&
            !url.startsWith("/triggers/library") &&
            !url.startsWith("/trigger-schedules")));
    }

    get isTriggerSchedulesActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/trigger-schedules")));
    }

    get isLibraryTriggersActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/triggers/library") ||
            /^\/triggers\/\w{32}\/stats/.exec(url) !== null));
    }

    get isThreatMatchActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/threat")));
    }

    get isWatchlistsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/watchlists")));
    }

    /** Tickets Pages */

    get isIncidentsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/tickets/incident")));
    }

    get isUniversalTicketInboxActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/universal-ticket-inbox")));
    }

    get isTicketTemplateActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/ticket-template")));
    }

    /** Fraud Pages */

    get isFraudInboxActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/fraud/inbox")));
    }

    get isFraudTriggersActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/fraud/triggers")));
    }

    get isFraudLibraryTriggersActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/fraud/library")));
    }

    get isFraudThreatmatchActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/fraud/threat")));
    }

    get isFraudWatchlistsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/fraud/watchlists")));
    }

    /** Compliance Pages */

    get isFrameworksActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/governance/framework")));
    }

    get isCustomPoliciesActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/governance/policies") ||
            url.startsWith("/governance/policy")));
    }

    get isCATPoliciesActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/governance/cat")));
    }

    get isTasksActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/governance/tasks")));
    }

    get isTaskSchedulesActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/task-schedule")));
    }

    get isLibraryTaskSchedulesActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/library-task-schedules")));
    }

    /** Risk Pages */

    get isControlsLibraryActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/risk/controls/library")));
    }

    get isControlsRegisterActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/risk/controls/register")));
    }

    get isRiskLibraryActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/risk/risks/library")));
    }

    get isRiskRegisterActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/risk/risks/register")));
    }

    get isInventoryRegisterActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/risk/inventory/register")));
    }

    get isInventoryLibraryActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/risk/inventory/library")));
    }

    get isAssessmentsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/risk/assessments")));
    }

    /** Assets Pages */

    get isAssetsListActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/assets")));
    }

    /** Reports Pages */

    get isReportsListActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/reports") &&
            !url.startsWith("/reports/templates") &&
            !url.startsWith("/reports/charts")));
    }

    get isReportTemplatesListActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/reports/templates")));
    }

    get isChartsListActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/reports/charts")));
    }

    /** Settings Pages */

    get isUsersListActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/settings/users")));
    }

    get isInputTokensListActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/settings/input-tokens")));
    }

    get isActionIntegrationsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/settings/action-integrations")));
    }

    get isIntegrationsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/settings/integrations")));
    }

    get isDataRestrictionsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/settings/data-restriction")));
    }

    get isUserProfileActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/settings/profile") ||
            url.startsWith("/settings/setup-totp-profile")));
    }

    get isOrgSettingsActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/settings/org-settings")));
    }

    get isRolesListActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/settings/role")));
    }

    get isEscalationPageActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/settings/escalation")));
    }

    get isJobDescriptorsPageActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/settings/job-descriptors")));
    }

    get isGlobalTagSearchActive$(): Observable<boolean> {
        return this.url$.pipe(map(url => url.startsWith("/settings/tags")));
    }
}
